import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { LoadingIcon } from "../../../components/LoadingIcon";
import Select from "react-select";

const View = ({
  onOk,
  onCancel,
  isLoading,
  isFaskesLoading,
  isDonaturLoading,
  listDonatur,
  data,
  onChange,
  onSelect,
  isOpen,
  listFakses
}) => {
  const _handleSubmit = e => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    if (!data.uang && !data.barang) {
      alert("Isi kolom uang atau barang");
    }
    onOk();
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <form onSubmit={_handleSubmit}>
        <ModalHeader toggle={onCancel}>Donasi Baru</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="exampleEmail">
              Donatur<span className="text-red">*</span>
            </Label>
            <Select
              isLoading={isDonaturLoading}
              name="color"
              options={listDonatur}
              onChange={onSelect.donatur}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">
              Faskes<span className="text-red">*</span>
            </Label>
            <Select
              isLoading={isFaskesLoading}
              name="color"
              options={listFakses}
              onChange={onSelect.faskes}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Jumlah Uang (IDR)</Label>
            <Input
              type="number"
              name="uang"
              onChange={e => onChange(e.target.name, e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Barang (Pisahkan dengan koma)</Label>
            <FormText color="muted">
              Contoh: APD Masker 100 buah, Hololens 100 buah, Hand sanitizer 10L
            </FormText>
            <Input
              type="textarea"
              name="barang"
              onChange={e => onChange(e.target.name, e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading}>
            {" "}
            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}{" "}
            Simpan
          </Button>
          <Button color="secondary" onClick={onCancel}>
            Batal
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default View;
