import { c } from "../constant";
import { observable, action } from "mobx";
import { getUsers as fetchUsers } from "../api/users";
import API from "../api/API";

interface IData {
  isDeleteConfirm: boolean;
  isError: boolean;
  isLoading: boolean;
  isUserModalForm: boolean;
  isFormVolunteer: boolean;
  showDetail: boolean;
  currentPage: number;
  totalPages: number;
  totalRows: number;
  rows: Array<any>;
  rowsWaiting: Array<any>;
  selectedId: string;
  selectedUser: any;
  form_volunteer: any;
  pageSize: number;
}
export interface IUserStore {
  data: IData;
  waitingList: {
    rows: Array<any>;
    currentPage: number;
    totalPage: number;
  };
  changeStatus(token: any, status: "accepted" | "rejected"): Promise<any>;
  getUsers(token: any, page?: number, size?: number): Promise<any>;
  getUser(token: any, id: string): Promise<any>;
  listByFormVolunteer(token: any, page?: number, size?: number): Promise<any>;
  search: (token: any, name: string) => Promise<any>;
  searchByFormVolunteer: (token: any, name: string) => Promise<any>;
  updateData(key: string, value: any): Promise<any>;
}

export class UserStore {
  @observable
  data: IData = {
    isDeleteConfirm: false,
    isError: false,
    isLoading: false,
    isUserModalForm: false,
    showDetail: false,
    currentPage: 1,
    totalPages: 0,
    totalRows: 0,
    rows: [],
    rowsWaiting: [],
    selectedId: "",
    selectedUser: {},
    form_volunteer: {
      address: "",
      address_domicile: "",
      email: "",
      birthdate: "",
      full_name: "",
      identity_number: "",
      phone_number: "",
      photo_certificate: ""
    },
    isFormVolunteer: false,
    pageSize: 10
  };

  @observable
  waitingList = {
    rows: [],
    currentPage: 1,
    totalPage: 1
  };

  @action
  updateData = (key: string, value: any) => {
    this.data[key] = value;
  };

  @action
  search = (token: any, name: string) => {
    const { updateData } = this;
    updateData(c.key.isLoading, true);

    return API.User.search(token, name)
      .then((data: any) => {
        updateData(c.key.rows, data.rows);
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true));
  };

  @action
  getUsers = (token: any, page: number = 1, size: number = 10) => {
    const { updateData } = this;
    this.data.currentPage = page;

    updateData(c.key.isLoading, true);
    return fetchUsers(token, size, page)
      .then((data: any) => {
        updateData(c.key.rows, data.rows);
        updateData("totalPages", data.last_page);
        updateData("totalRows", data.total);
      })
      .catch(() => updateData(c.key.isError, true))
      .finally(() => updateData(c.key.isLoading, false));
  };

  @action
  listByFormVolunteer = (token: any, page: number = 1, size: number = 10) => {
    const { updateData } = this;
    this.waitingList.currentPage = page;
    const STATUS = "waiting";

    updateData(c.key.isLoading, true);
    return API.User.listByFormVolunteer(token, size, page, STATUS)
      .then((data: any) => {
        this.waitingList.rows = data.rows;
        this.waitingList.totalPage = data.last_page;
      })
      .catch(() => updateData(c.key.isError, true))
      .finally(() => updateData(c.key.isLoading, false));
  };

  @action
  searchByFormVolunteer = (token: any, value: string) => {
    const { updateData } = this;
    const STATUS = "waiting";

    updateData(c.key.isLoading, true);
    return API.User.searchByFormVolunteer(token, value, STATUS)
      .then((data: any) => {
        this.waitingList.rows = data.rows;
      })
      .catch(() => updateData(c.key.isError, true))
      .finally(() => updateData(c.key.isLoading, false));
  };

  @action
  getUser = (token: any, id: string) => {
    const { updateData } = this;
    updateData(c.key.isLoading, true);

    return API.User.getByID(token, id)
      .then((data: any) => {
        updateData("selectedUser", data);
        if (data.form_volunteer) {
          updateData("form_volunteer", data.form_volunteer);
          updateData("isFormVolunteer", true);
        } else {
          updateData("isFormVolunteer", false);
        }
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true));
  };

  @action
  changeStatus = (token: any, status: "accepted" | "rejected") => {
    const { updateData, data } = this;
    updateData(c.key.isLoading, true);

    const payload = {
      id_user: data.selectedUser._id,
      id_form: data.form_volunteer._id,
      status
    };

    return API.User.changeStatus(token, payload)
      .then((data: any) => {
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true));
  };
}

export default new UserStore();
