import * as React from "react";
import { toast } from "react-toastify";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { TopNav } from "../components/TopNav";
import { SideNav } from "../components/SideNav";
import { Dashboard } from "./Dashboard";
import { Ambulance } from "./Ambulance";
import { Community } from "./Community";
import { Emergency } from "./Emergency";
import { EmergencyDetail } from "./EmergencyDetail";
import { Hospital } from "./Hospital";
import { Volunteer } from "./Volunteer";
import { User } from "./User";
import Donation from "./donation";
import { auth } from "../api/auth";

import {
  addHandler,
  close,
  connect,
  deleteHandler,
  socket,
} from "../api/emergency-ws";

class Home extends React.Component<{}, any> {
  _socket: any;
  _sirineRef: any;

  constructor(props: any) {
    super(props);

    this._sirineRef = React.createRef<HTMLAudioElement>();
    this._handleMessageReceived = this._handleMessageReceived.bind(this);
    this.state = {
      play: false,
    };

    // Connect websocket if there is no connection yet
    if (!socket) {
      connect();
    }
  }

  _handleMessageReceived(e: any) {
    const data = JSON.parse(e.data);
    const user = auth.getUserData();
    if (!data.data) {
      return;
    }
    const emergencyId = data.ide;

    if (emergencyId) {
      if (
        user.role_id.group === "admin" &&
        (user.province !== data.data.sender_data.province ||
          user.city !== data.data.sender_data.city)
      ) {
        return;
      }
      console.log("WS: New emergency", emergencyId);

      // Play sirine
      // Note: this will not work in Chrome, as https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      this._sirineRef.current.play();

      // Show toast message
      toast.warn(
        <React.Fragment>
          Darurat Bantuan Baru!
          <Link
            to={"/emergency/" + emergencyId}
            className="btn btn-sm btn-primary ml-2"
          >
            Pantau
          </Link>
        </React.Fragment>,
        {
          autoClose: 30000,
        }
      );
    }
  }

  componentDidMount() {
    addHandler("MESSAGE_RECEIVED", this._handleMessageReceived);
  }

  componentWillUnmount() {
    deleteHandler("MESSAGE_RECEIVED");
    close();
  }

  render() {
    return (
      <React.Fragment>
        <SideNav />
        <div className="main-content">
          <TopNav title="Dashboard" username="Ivan" />
          <Switch>
            <Route path="/ambulance" component={Ambulance} />
            <Route path="/community" component={Community} />
            <Route path="/emergency/:id" component={EmergencyDetail} />
            <Route path="/emergency" component={Emergency} />
            <Route path="/hospital" component={Hospital} />
            <Route path="/volunteer" component={Volunteer} />
            <Route path="/user" component={User} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/donation" component={Donation} />
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </div>
        <audio
          ref={this._sirineRef}
          src={process.env.PUBLIC_URL + "assets/audio/sirine.mp3"}
        />
      </React.Fragment>
    );
  }
}

export { Home };
