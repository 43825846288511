import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "mobx-react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// import "./scss/misc.scss";
import "./scss/argon.scss";
import "react-toastify/dist/ReactToastify.css";

import { App } from "./containers/App";
import ambulanceStore from "./stores/ambulanceStore";
import communityStore from "./stores/communityStore";
import dashboardStore from "./stores/dashboardStore";
import emergencyStore from "./stores/emergencyStore";
import placeStore from "./stores/placeStore";
import userStore from "./stores/userStore";
import volunteerStore from "./stores/volunteerStore";

const stores = {
  ambulanceStore,
  communityStore,
  dashboardStore,
  emergencyStore,
  placeStore,
  userStore,
  volunteerStore,
};

Sentry.init({
  dsn:
    "https://f97f9904636147e98593205b4d3a255a@o338941.ingest.sentry.io/5555200",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
