import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { Home } from "./Home";

function App() {
  return (
    <React.Fragment>
      <ToastContainer hideProgressBar={true} />

      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <AuthenticatedRoute path="/" component={Home} />
      </Switch>
    </React.Fragment>
  );
}

export { App };
