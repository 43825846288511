import React from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";

import { UserFields, createUser, getUser, updateUser } from "../api/users";
import { getPlaces, getProvincies, getCitites } from "../api/places";
import { getHeroCategories } from "../api/heroes";
import { axios } from "../api/config";
import { AuthenticatedUserContext } from "../contexts";

interface UserModalFormProps {
  /** Modal visibility */
  isOpen?: boolean;
  /** Selected ID, used to get data on EDIT mode */
  updateId?: string;
  /** Title of the modal */
  title: string;
  /** On success form submit callback */
  onSuccess: () => any;
  /** Handles modal close, should change isOpen props from parent component */
  onClosed: () => any;
}

interface UserModalFormState {
  /** Array of categories to store hero categories */
  categories: Array<any>;
  /** Form fields */
  fields: UserFields;
  /** Array of jobplaces to store data places by hero category */
  jobplaces: Array<JobPlace>;
  provincies: Array<any>;
  cities: Array<any>;
  /** Error's state */
  isError: boolean;
  /** Loading's state */
  isLoading: boolean;
}

interface JobPlace {
  id: string;
  name: string;
}

class UserModalForm extends React.Component<
  UserModalFormProps,
  UserModalFormState
> {
  _source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this.state = {
      fields: {
        address: "",
        category: "user",
        email: "",
        fullName: "",
        lat: 0,
        lon: 0,
        password: "",
        phoneNumber: "",
        role: "user",
        province: "",
        city: "",
      },
      jobplaces: [],
      categories: [],
      provincies: [],
      cities: [],
      isError: false,
      isLoading: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._loadUser = this._loadUser.bind(this);
    this._loadJobplaces = this._loadJobplaces.bind(this);
    this._loadProvinvies = this._loadProvinvies.bind(this);
    this._loadCities = this._loadCities.bind(this);
    this._loadHeroCategories = this._loadHeroCategories.bind(this);
  }

  _handleChange(e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    const { name, value } = e.currentTarget;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  }

  async _handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { fields } = this.state;
    const { onSuccess, updateId } = this.props;
    this.setState({ isLoading: true });

    if (
      !fields.address &&
      !fields.lat &&
      !fields.lon &&
      !fields.fullName &&
      !fields.password &&
      !fields.phoneNumber
    ) {
      return false;
    }

    try {
      if (updateId) {
        await updateUser(updateId, fields);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil diedit" />
        );
      } else {
        await createUser(fields, fields.category);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil ditambahkan" />
        );
        this.setState({
          fields: {
            address: "",
            category: "user",
            email: "",
            fullName: "",
            lat: 0,
            lon: 0,
            password: "",
            phoneNumber: "",
            role: "user",
          },
        });
      }

      if (typeof onSuccess === "function") {
        onSuccess();
        this.props.onClosed();
      }
    } catch (error) {
      this.setState({ isError: true });
    }

    this.setState({
      isLoading: false,
    });
  }

  _handleToggle() {
    if (!this.state.isLoading) {
      this.props.onClosed();
    }
  }

  async _loadUser() {
    console.log("Fetching user data");
    this.setState({ isLoading: true });

    try {
      const response = await getUser(this.props.updateId || "");
      const data = response.data;
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          address: data["address"],
          category: data["category"] ? data["category"]["type"] : "user",
          email: data["email"],
          lat: data["location"]["coordinates"][1],
          lon: data["location"]["coordinates"][0],
          fullName: data["full_name"],
          password: data["password"],
          phoneNumber: data["phone_number"],
          jobplaceId: data["jobplace_id"]
            ? data["jobplace_id"]["_id"]
            : undefined,
        },
      }));
    } catch (err) {
      console.log(err);
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async _loadHeroCategories() {
    this.setState({ isLoading: true });

    try {
      const response = await getHeroCategories(this._source.token, {
        size: 100,
        page: 1,
      });
      const categories = response.data.rows;
      this.setState({
        categories: categories,
      });
    } catch (err) {
      console.log(err);
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async _loadProvinvies() {
    this.setState({ isLoading: true });

    try {
      const response = await getProvincies(this._source.token);
      const provincies = response.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setState({
        provincies: provincies,
      });
    } catch (err) {
      console.log(err);
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async _loadCities() {
    this.setState({ isLoading: true });

    try {
      const response = await getCitites(
        this._source.token,
        this.state.fields.province!
      );
      const cities = response.sort();
      this.setState({
        cities: cities,
      });
    } catch (err) {
      console.log(err);
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async _loadJobplaces() {
    this.setState({ isLoading: true });

    try {
      const data = await getPlaces(
        this._source.token,
        { size: 1000, page: 1 },
        this.state.fields.category
      );
      const places: Array<JobPlace> = data.rows.map((place: any) => ({
        id: place._id,
        name: place.data.name,
      }));
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
        },
        jobplaces: places,
      }));
    } catch (err) {
      console.log(err);
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this._loadHeroCategories();
    this._loadProvinvies();
  }

  componentDidUpdate(
    prevProps: UserModalFormProps,
    prevState: UserModalFormState
  ) {
    // if modal is dismissed, then reset field states
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        fields: {
          address: "",
          email: "",
          category: "user",
          jobplaceId: undefined,
          lat: 0,
          lon: 0,
          fullName: "",
          password: "",
          phoneNumber: "",
          role: "user",
        },
        isError: false,
      });
    }

    // if modal is shown and on EDIT mode, then fetch data based on updateId
    if (this.props.isOpen && !prevProps.updateId && this.props.updateId) {
      this._loadUser();
    }

    // if category is changed, then fetch data jobplaces based on category
    if (
      this.props.isOpen &&
      this.state.fields.category !== "user" &&
      this.state.fields.category !== "volunteer" &&
      // !prevState.fields.jobplaceId &&
      prevState.fields.category !== this.state.fields.category
    ) {
      console.log("JOB_ID", this.state.fields.jobplaceId);
      this._loadJobplaces();
    }

    // if category is "user" or "volunteer", then reset jobplaces
    if (
      this.props.isOpen &&
      prevState.fields.category !== this.state.fields.category &&
      (this.state.fields.category == "user" ||
        this.state.fields.category == "volunteer")
    ) {
      this.setState({
        jobplaces: [],
        fields: {
          ...this.state.fields,
          jobplaceId: undefined,
        },
      });
    }

    // Update cities
    if (
      this.props.isOpen &&
      prevState.fields.province !== this.state.fields.province
    ) {
      this._loadCities();
    }
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { isOpen, title, updateId } = this.props;
    const {
      cities,
      provincies,
      categories,
      fields,
      isError,
      isLoading,
      jobplaces,
    } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>
          {title} : {updateId ? "EDIT" : "TAMBAH"}
        </ModalHeader>
        <ModalBody>
          {isError && (
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          )}

          <Form onSubmit={this._handleSubmit}>
            <FormGroup>
              <Label for="fullName">Nama</Label>
              <Input
                defaultValue={fields.fullName}
                disabled={isLoading}
                id="fullName"
                name="fullName"
                placeholder="Masukkan nama"
                required={true}
                type="text"
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                defaultValue={fields.email}
                disabled={isLoading}
                id="email"
                name="email"
                placeholder="Masukkan email"
                required={true}
                type="email"
                onChange={this._handleChange}
              />
            </FormGroup>
            <AuthenticatedUserContext.Consumer>
              {(user) => (
                <>
                  {user.role === "superadmin" && (
                    <FormGroup>
                      <Label for="role">Role</Label>
                      <select
                        value={fields.role}
                        disabled={isLoading}
                        id="role"
                        name="role"
                        required={true}
                        onChange={this._handleChange}
                        className="form-control"
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </select>
                    </FormGroup>
                  )}
                </>
              )}
            </AuthenticatedUserContext.Consumer>

            {fields.role === "user" && (
              <FormGroup>
                <Label for="category">Kategori Hero</Label>
                <select
                  value={fields.category}
                  disabled={isLoading}
                  id="category"
                  name="category"
                  required={true}
                  onChange={this._handleChange}
                  className="form-control"
                >
                  {categories.map((hero) => (
                    <option value={hero.type} key={hero._id}>
                      {hero.title}
                    </option>
                  ))}
                </select>
              </FormGroup>
            )}
            {fields.role === "admin" && (
              <>
                <FormGroup>
                  <Label for="category">Provinsi</Label>
                  <select
                    value={fields.province}
                    disabled={isLoading}
                    id="province"
                    name="province"
                    required={true}
                    onChange={this._handleChange}
                    className="form-control"
                  >
                    <option>Pilih Provinsi</option>
                    {provincies.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label for="category">Kota</Label>
                  <select
                    value={fields.city}
                    disabled={isLoading}
                    id="city"
                    name="city"
                    required={true}
                    onChange={this._handleChange}
                    className="form-control"
                  >
                    <option>Pilih Kota</option>
                    {cities.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </>
            )}
            {jobplaces.length > 0 && (
              <FormGroup>
                <Label for="jobplaceId">Tempat Kerja</Label>
                <select
                  value={fields.jobplaceId}
                  disabled={isLoading}
                  id="jobplaceId"
                  name="jobplaceId"
                  required={true}
                  onChange={this._handleChange}
                  className="form-control"
                >
                  {jobplaces.map((place: JobPlace) => (
                    <option value={place.id} key={place.id}>
                      {place.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            )}
            {!updateId && (
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  defaultValue={fields.password}
                  disabled={isLoading}
                  id="password"
                  name="password"
                  placeholder="Masukkan password"
                  required={true}
                  type="password"
                  onChange={this._handleChange}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="address">Alamat</Label>
              <Input
                defaultValue={fields.address}
                disabled={isLoading}
                id="address"
                name="address"
                placeholder="Masukkan alamat"
                required={true}
                type="text"
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Nomor telepon</Label>
              <Input
                defaultValue={fields.phoneNumber}
                disabled={isLoading}
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Masukkan nomor telepon"
                required={true}
                type="text"
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lat">Latitude (LAT)</Label>
              <Input
                value={fields.lat.toString()}
                disabled={isLoading}
                id="lat"
                name="lat"
                placeholder="Masukkan latitude"
                required={true}
                type="number"
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Latitude harus bernilai desimal. Contoh: -8.182
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="lon">Longitude (LNG)</Label>
              <Input
                value={fields.lon.toString()}
                disabled={isLoading}
                id="lon"
                name="lon"
                placeholder="Masukkan longitude"
                required={true}
                type="number"
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Longitude harus bernilai desimal. Contoh: 111.117
              </FormText>
            </FormGroup>

            <Button type="submit" color="primary" disabled={isLoading}>
              Submit
            </Button>

            <Button
              type="button"
              color="default"
              disabled={isLoading}
              onClick={this._handleToggle}
            >
              Batal
            </Button>

            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export { UserModalForm };
