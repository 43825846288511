import * as React from "react";

interface CountdownProps {
  /** End time of countdown */
  endTime: number;
}

interface CountdownState {
  /** Difference between now and the endTime */
  timeLeft: TimeLeft;
}

type TimeLeft = {
  min: number;
  sec: number;
};

class Countdown extends React.Component<CountdownProps, CountdownState> {
  _timer: any;

  constructor(props: CountdownProps) {
    super(props);

    this.state = {
      timeLeft: {
        min: 0,
        sec: 0
      }
    };

    this._tick = this._tick.bind(this);
  }

  _tick() {
    // calculate difference between now and the end date
    let diff = (this.props.endTime - Date.now()) / 1000;

    if (diff <= 0) {
      this._stop();
      return false;
    }

    let timeLeft: TimeLeft = {
      min: 0,
      sec: 0
    };

    timeLeft.min = Math.floor(diff / 60);
    timeLeft.sec = Math.floor(diff % 60);
    this.setState({ timeLeft: timeLeft });
  }

  _stop() {
    clearInterval(this._timer);
  }

  componentDidMount() {
    this._timer = setInterval(this._tick, 1000);
  }

  componentWillUnmount() {
    this._stop();
  }

  render() {
    const { min, sec } = this.state.timeLeft;
    let icon;
    let minString = min < 10 ? "0" + min : min;
    let secString = sec < 10 ? "0" + sec : sec;

    if (min < 2) {
      icon = "text-danger";
    } else if (min < 4) {
      icon = "text-warning";
    } else {
      icon = "text-success";
    }

    return (
      <React.Fragment>
        <i className={"mr-2 fa fa-circle " + icon} />
        {minString + ":" + secString}
      </React.Fragment>
    );
  }
}

export { Countdown };
