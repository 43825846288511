import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { ModalPlaceForm } from "./ModalPlaceForm";
import { DeleteConfirm } from "./DeleteConfirm";

interface HospitalDataTableProps {
  isLoading: boolean;
  rows: Array<any>;
  currentPage: number;
  totalPage: number;
  loadData: (page?: number) => any;
  onSearch: (value: string) => any;
}

interface HospitalDataTableState {
  isDeleteConfirm: boolean;
  isModalPlaceForm: boolean;
  selectedId: string;
}

class HospitalDataTable extends React.Component<
  HospitalDataTableProps,
  HospitalDataTableState
> {
  constructor(props: HospitalDataTableProps) {
    super(props);
    this.state = {
      isDeleteConfirm: false,
      isModalPlaceForm: false,
      selectedId: ""
    };

    this._search = this._search.bind(this);
  }

  _search(value: string) {
    const { loadData, onSearch } = this.props;
    if (value === "") {
      loadData();
    } else {
      onSearch(value);
    }
  }

  render() {
    const { isLoading, loadData, rows, currentPage, totalPage } = this.props;
    const { isDeleteConfirm, isModalPlaceForm, selectedId } = this.state;

    return (
      <React.Fragment>
        <ModalPlaceForm
          isOpen={isModalPlaceForm}
          updateId={selectedId}
          placeType="hospital"
          title="Form Rumah Sakit & Fasilitas Kesehatan"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isModalPlaceForm: false, selectedId: "" });
          }}
        />
        <DeleteConfirm
          isOpen={isDeleteConfirm}
          deleteId={selectedId}
          deleteType="place"
          title="Apakah Anda yakin akan menghapus item?"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isDeleteConfirm: false, selectedId: "" });
          }}
        />
        <DataTable2
          headings={["NAMA", "TELEPON", ""]}
          isLoading={isLoading}
          title="List Rumah Sakit & Faskes"
          currentPage={currentPage}
          totalPages={totalPage}
          onPaginate={loadData}
          toolbar={
            <Button
              color="primary"
              size="sm"
              onClick={() => this.setState({ isModalPlaceForm: true })}
            >
              Tambah
            </Button>
          }
          onSearch={this._search}
          renderRows={() =>
            rows.map((place: any) => (
              <DataTableRow
                key={place._id}
                id={place._id}
                nama={place.data.name}
                telepon={place.data.phone}
                onSuccess={loadData}
                onShowDeleteConfirm={() => {
                  this.setState({
                    isDeleteConfirm: true,
                    selectedId: place._id
                  });
                }}
                onShowModalPlaceForm={() => {
                  this.setState({
                    isModalPlaceForm: true,
                    selectedId: place._id
                  });
                }}
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  nama,
  telepon,
  onShowDeleteConfirm,
  onShowModalPlaceForm
}: any) {
  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <div className="media-body">
            <span className="mb-0 text-sm">{nama}</span>
          </div>
        </div>
      </th>
      <td>
        <span className="badge badge-dot mr-4">{telepon}</span>
      </td>
      <td>
        <Button color="warning" size="sm" onClick={onShowModalPlaceForm}>
          Edit
        </Button>
        <Button color="danger" size="sm" onClick={onShowDeleteConfirm}>
          Hapus
        </Button>
      </td>
    </tr>
  );
}

export { HospitalDataTable };
