import WebSocket from "isomorphic-ws";
import { WS_BASE_URL } from "./config";
/** Store websocket object */
let socket: any;
/** Registered handlers of onmessage event of the socket */
let socketHandlers = new Map();

function connect() {
  socket = new WebSocket(`${WS_BASE_URL}/v1/websocket/emergency`);

  socket.addEventListener("error", function(err: any) {
    console.log("WS: Error");
    console.log(err);
  });

  socket.addEventListener("open", function() {
    console.log("WS: Opened");
  });

  socket.addEventListener("close", function(e: any) {
    console.log("WS: Closed");
    const closeCode = e.code;

    // Reconnectiong if:
    // 1001: client is leaving (refresh tab)
    // 1006: abnormal closing
    if (closeCode == 1001 || closeCode == 1006) {
      console.log("WS: Reconnecting in 1 second..");

      socketHandlers.forEach((handler: any) =>
        socket.removeEventListener("message", handler)
      );
      setTimeout(() => connect(), 1000);
    }
  });

  socket.addEventListener("message", function(e: any) {
    // Register handlers as socket's onmessage event listener
    socketHandlers.forEach((handler: any) => handler(e));
  });
}

/** Add handler to socketHandlers */
function addHandler(handlerName: string, onMessageReceived: (e: any) => any) {
  socketHandlers.set(handlerName, onMessageReceived);
}

/**  Also remove from socketHandlers */
function deleteHandler(handlerName: string) {
  socketHandlers.delete(handlerName);
}

/** Close socket connection */
function close() {
  socketHandlers.forEach((handler: any) =>
    socket.removeEventListener("message", handler)
  );
  socketHandlers.clear();
  socket.close();
  socket = undefined;
}

export { addHandler, close, connect, deleteHandler, socket };
