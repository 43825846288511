import * as React from "react";
import { UserDataTable } from "../components/UserDataTable";
import { Button } from "reactstrap";

interface UserState {
  isError: boolean;
  isLoading: boolean;
  tab: string;
}

class User extends React.Component<{}, UserState> {
  constructor(props: any) {
    super(props);
    this.changeTab = this.changeTab.bind(this);

    this.state = { isError: false, isLoading: false, tab: "user" };
  }

  changeTab(tab) {
    this.setState({ tab });
  }

  render() {
    const { isError, isLoading, tab } = this.state;

    return (
      <React.Fragment>
        <div className="header bg-gradient-success pb-8 pt-5 pt-md-8" />

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}
          <div className="row mb-2">
            <div className="col">
              {/* <Button
                color="primary"
                onClick={() => this.changeTab("user")}
                disabled={tab === "user"}
              >
                Data Pengguna
              </Button> */}
              {/* <Button
                color="primary"
                onClick={() => this.changeTab("waiting")}
                disabled={tab === "waiting"}
              >
                Menunggu Konfirmasi
              </Button> */}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <UserDataTable isLoading={isLoading} type={tab} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { User };
