import * as React from "react";
import { Button } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import { Countdown } from "../components/Countdown";
import { LoadingIcon } from "../components/LoadingIcon";
import { Map, MarkerAttributes } from "../components/Map";
import { ModalConfirm } from "../components/ModalConfirm";
import { FinishEmergencyModalForm } from "../components/FinishEmergencyModalForm";
import { NearbyDataTable } from "../components/NearbyDataTable";
import ModalImage from "react-modal-image";

import { doEmergencyAction, getEmergency } from "../api/emergency";
import axios from "axios";

import IconHelpMarker from "../img/icons/marker/loc_me.svg";
import IconHeroes from "../img/icons/marker/loc_police.svg";

import {
  addHandler,
  close,
  connect,
  deleteHandler,
  socket,
} from "../api/emergency-detail-ws";

const ROOT_IMAGE = process.env.REACT_APP_PATH_IMG_STATIC;

interface EmergencyDetailState {
  /** Visibility of ModalConfirm for CANCEL action */
  isConfirmCancel: boolean;
  /** Visibility of ModalConfirm for FINISH action */
  isConfirmFinish: boolean;
  /** Error's state */
  isError: boolean;
  /** Loading's state */
  isLoading: boolean;
  /** Emergency data object */
  emergency: any;

  markers: Array<MarkerAttributes>;
}

class EmergencyDetail extends React.Component<
  RouteComponentProps<any>,
  EmergencyDetailState
> {
  _source = axios.CancelToken.source();

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isConfirmCancel: false,
      isConfirmFinish: false,
      isError: false,
      isLoading: false,
      emergency: {},
      markers: [],
    };
    this._loadData = this._loadData.bind(this);
    this._handleMessageReceived = this._handleMessageReceived.bind(this);
    // Connect websocket if there is no connection yet
    if (!socket) {
      connect(this.props.match.params.id);
    }
  }

  async _loadData() {
    try {
      this.setState({ isLoading: true });
      const { data } = await getEmergency(
        this.props.match.params.id,
        this._source.token
      );

      const user = data.id_users || {};
      const newMarkers: Array<MarkerAttributes> = [];

      data.heroes.forEach((hero) => {
        newMarkers.push({
          id: hero._id,
          icon: IconHeroes,
          lat: hero.id_users.location.coordinates[1],
          lng: hero.id_users.location.coordinates[0],
          infoWindow: `<h4>${hero.id_users.full_name}</h4> ${hero.id_users
            .phone_number || "-"}`,
        });
      });

      if (user.location) {
        newMarkers.push({
          id: user._id,
          icon: IconHelpMarker,
          lat: user.location.coordinates[1],
          lng: user.location.coordinates[0],
          infoWindow: `<h4>${user.full_name}</h4> ${user.phone_number || "-"}`,
        });
      }
      this.setState({
        emergency: data,
        markers: newMarkers,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ isError: true, isLoading: false });
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  _handleMessageReceived(e: any) {
    const data = JSON.parse(e.data);
    if (data.flag === "hero_enter") {
      this._loadData();
    }
    if (data.flag === "hero_updating" || data.flag === "user_updating") {
      this._updateMarker(
        data.flag,
        data.data.sender_data,
        data.data.sender_location
      );
    }
  }

  _updateMarker(type, user, location) {
    const { markers } = this.state;
    const idx = markers.findIndex((m) => m.id === user._id);
    let icon = IconHeroes;
    if (type === "user_updating") {
      icon = IconHelpMarker;
    }
    if (idx >= 0) {
      markers[idx] = {
        id: user._id,
        icon,
        lat: location[1],
        lng: location[0],
        infoWindow: `<h4>${user.full_name}</h4> ${user.phone_number || "-"}`,
      };
    }

    this.setState({ markers });
  }

  componentDidMount() {
    addHandler("MESSAGE_RECEIVED", this._handleMessageReceived);
    this._loadData();
    setTimeout(function () {
      window.location.reload();
    }, 60000);
  }

  componentWillUnmount() {
    deleteHandler("MESSAGE_RECEIVED");
    close();
    this._source.cancel("CANCELED");
  }

  componentDidUpdate(prevProps: RouteComponentProps<any>) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      close();
      connect(this.props.match.params.id);
    }
  }

  render() {
    const {
      emergency,
      isConfirmCancel,
      isConfirmFinish,
      isError,
      isLoading,
    } = this.state;
    const user = emergency.id_users || {};

    let Heroes: Array<any> = [];

    if (emergency.heroes) {
      Heroes = emergency.heroes.map((value: any) => (
        <div key={value._id} className="col-xl-4 col-md-4 mb-3 mt-5 ">
          <div className="card card-profile shadow">
            <div className="row justify-content-center">
              <div className="col-lg-3 order-lg-2">
                <div className="card-profile-image">
                  <a href="#">
                    <img
                      src={value.id_users.photo}
                      className="rounded-circle"
                      alt={`Foto dari ${value.id_users.full_name}`}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
            <div className="card-body pt-0 pt-md-4">
              <div className="text-center">
                <h3>
                  {value.id_users.full_name}
                  <span className="font-weight-light" />
                </h3>
                <h5 className="font-weight-300">{value.id_users.address}</h5>
                <h5 className="font-weight-300">
                  {value.id_users.phone_number}
                </h5>
              </div>
            </div>
          </div>
        </div>
      ));
    }

    return (
      <React.Fragment>
        <ModalConfirm
          isOpen={isConfirmCancel}
          text={`Apakah Anda yakin untuk mengabaikan darurat bantuan dari ${user.full_name
            }?`}
          successMessage={`Darurat bantuan dari ${user.full_name
            } berhasil diabaikan`}
          onConfirmed={() => doEmergencyAction(emergency._id, "cancel")}
          onSuccess={() => this._loadData()}
          onClosed={() => {
            this.setState({
              isConfirmCancel: false,
            });
          }}
        />
        <FinishEmergencyModalForm
          isOpen={isConfirmFinish}
          updateId={emergency._id}
          onSuccess={() => this._loadData()}
          onClosed={() => {
            this.setState({
              isConfirmFinish: false,
            });
          }}
        />

        <div className="header bg-success pb-8 pt-5 pt-md-8" />
        {isError && (
          <div className="container-fluid mt--7">
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col-xl-12">
                <LoadingIcon />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                <div className="card card-profile shadow">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 order-lg-2">
                      <div className="card-profile-image">
                        <a href="#">
                          <img
                            src={user.photo ? user.photo : "https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"}
                            className="rounded-circle"
                            alt={`Foto dari ${user.full_name}`}
                            width={100} height={100}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                  <div className="card-body pt-0 pt-md-4">
                    <div className="text-center">
                      <h3>
                        {user.full_name}
                        <span className="font-weight-light" />
                      </h3>
                      <h5 className="font-weight-300">{user.address}</h5>
                      <h5 className="font-weight-300">{user.phone_number}</h5>
                      <hr />
                      <h5 className="font-weight-300">
                        Status:
                        <span className="badge badge-light">
                          {emergency.current_status}
                        </span>
                      </h5>
                      {emergency.created_at && (
                        <React.Fragment>
                          <h5 className="font-weight-300">
                            Waktu darurat masuk:
                            <span className="badge badge-light">
                              {new Date(emergency.created_at).toString()}
                            </span>
                          </h5>
                          <h1>
                            <Countdown
                              endTime={
                                Date.parse(emergency.created_at) +
                                5 * 60 * 1000 /* +5min */
                              }
                            />
                          </h1>
                        </React.Fragment>
                      )}
                      {(emergency.current_status == "waiting" ||
                        emergency.current_status == "accepted" ||
                        emergency.current_status == "on_going") && (
                          <React.Fragment>
                            <hr />
                            <Button
                              color="danger"
                              size="lg"
                              onClick={() =>
                                this.setState({ isConfirmCancel: true })
                              }
                            >
                              Abaikan
                            </Button>
                            <Button
                              color="success"
                              size="lg"
                              onClick={() =>
                                this.setState({ isConfirmFinish: true })
                              }
                            >
                              Selesai!
                            </Button>
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 order-xl-1">
                <div className="card bg-secondary shadow">
                  <div className="card-header bg-white border-0">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h3 className="mb-0">Lokasi Korban</h3>
                      </div>
                      <div className="col-4 text-right">
                        <a href="#!" className="btn btn-sm btn-primary">
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col">
                      {user.location && (
                        <Map
                          markers={this.state.markers}
                          center={{
                            lat: user.location.coordinates[1],
                            lng: user.location.coordinates[0],
                          }}
                          zoom={15}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-3" />

            <h3>Informasi Penolong</h3>
            <div className="row">
              {Heroes.length > 0 ? (
                Heroes
              ) : (
                <div className="col-md-12 text-center text-danger my-5">
                  <h3>Belum ada penolong</h3>
                </div>
              )}
            </div>

            {emergency.photos && (
              <>
                <h3>Foto</h3>
                <div className="row mb-3">
                  {emergency.photos.map((value) => (
                    <div key={value} className="col-2">
                      <ModalImage
                        small={`${ROOT_IMAGE}${value}`}
                        large={`${ROOT_IMAGE}${value}`}
                        className="img-fluid"
                      />
                    </div>
                  ))}
                  {emergency.photos.length === 0 && (
                    <div className="col-md-12 text-center text-danger my-5">
                      <h3>Belum ada foto</h3>
                    </div>
                  )}
                </div>
              </>
            )}

            {user.location && (
              <div className="row">
                <div className="col-xl-6 mb-3">
                  <NearbyDataTable
                    emergencyId={emergency._id}
                    heroType="volunteer"
                    location={{
                      lat: user.location.coordinates[1],
                      lng: user.location.coordinates[0],
                    }}
                    title="Relawan Terdekat"
                  />
                </div>
                <div className="col-xl-6 mb-3">
                  <NearbyDataTable
                    emergencyId={emergency._id}
                    heroType="hospital"
                    location={{
                      lat: user.location.coordinates[1],
                      lng: user.location.coordinates[0],
                    }}
                    title="Rumah Sakit dan Fasilitas Kesehatan Terdekat"
                  />
                </div>
                <div className="col-xl-6 mb-3">
                  <NearbyDataTable
                    emergencyId={emergency._id}
                    heroType="ambulance"
                    location={{
                      lat: user.location.coordinates[1],
                      lng: user.location.coordinates[0],
                    }}
                    title="Ambulan Terdekat"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export { EmergencyDetail };
