import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { ImageModal } from "./ImageModal";
import { VolunteerModalForm } from "./VolunteerModalForm";
import { VolunteerModalDetail } from "./VolunteerModalDetail";
import { DeleteConfirm } from "./DeleteConfirm";
import { toJS } from "mobx";

interface VolunteerDataTableProps {
  isLoading: boolean;
  rows: Array<any>;
  currentPage: number;
  totalPage: number;
  loadData: (page?: number) => any;
  onSearch: (value: string) => any;
}

interface VolunteerDataTableState {
  isDeleteConfirm: boolean;
  isVolunteerModalForm: boolean;
  isVolunteerDetail: boolean;
  isImageModal: boolean;
  selectedSrc: string;
  selectedId: string;
}

class VolunteerDataTable extends React.Component<
  VolunteerDataTableProps,
  VolunteerDataTableState
> {
  constructor(props: any) {
    super(props);

    this._search = this._search.bind(this);
    this._openImage = this._openImage.bind(this);
  }

  state = {
    isDeleteConfirm: false,
    isVolunteerModalForm: false,
    isVolunteerDetail: false,
    isImageModal: false,
    selectedSrc: "",
    selectedId: "",
  };

  _openImage(src: string) {
    this.setState({ isImageModal: true, selectedSrc: src });
  }

  _search(value: string) {
    const { loadData, onSearch } = this.props;
    if (value === "") {
      loadData();
    } else {
      onSearch(value);
    }
  }

  render() {
    const { isLoading, loadData, rows, currentPage, totalPage } = this.props;
    const {
      selectedId,
      selectedSrc,
      isDeleteConfirm,
      isVolunteerModalForm,
      isImageModal,
      isVolunteerDetail,
    } = this.state;

    return (
      <React.Fragment>
        {isImageModal && (
          <ImageModal
            src={selectedSrc}
            onClosed={() =>
              this.setState({ isImageModal: false, selectedSrc: "" })
            }
          />
        )}

        <VolunteerModalDetail
          isOpen={isVolunteerDetail}
          userId={selectedId}
          onClosed={() =>
            this.setState({ isVolunteerDetail: false, selectedId: "" })
          }
        />

        <VolunteerModalForm
          isOpen={isVolunteerModalForm}
          updateId={selectedId}
          title="Form Relawan"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isVolunteerModalForm: false, selectedId: "" });
          }}
        />
        <DeleteConfirm
          isOpen={isDeleteConfirm}
          deleteId={selectedId}
          deleteType="user"
          title="Apakah Anda yakin akan menghapus item?"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isDeleteConfirm: false, selectedId: "" });
          }}
        />
        <DataTable2
          headings={["NAMA", "STATUS", ""]}
          isLoading={isLoading}
          title="List Relawan"
          onSearch={this._search}
          currentPage={currentPage}
          totalPages={totalPage}
          onPaginate={loadData}
          toolbar={
            <Button
              color="primary"
              size="sm"
              onClick={() => this.setState({ isVolunteerModalForm: true })}
            >
              Tambah
            </Button>
          }
          renderRows={() =>
            rows.map((volunteer: any) => (
              <DataTableRow
                key={volunteer._id}
                nama={volunteer.full_name}
                photo={volunteer.photo}
                status={volunteer.status}
                onSuccess={loadData}
                openImage={this._openImage}
                userStatus={
                  volunteer.form_volunteer
                    ? volunteer.form_volunteer.status
                    : ""
                }
                isFromVolunteer={
                  typeof volunteer.form_volunteer !== "undefined"
                }
                onShowUserModalDetail={() =>
                  this.setState({
                    isVolunteerDetail: true,
                    selectedId: volunteer._id,
                  })
                }
                onShowDeleteConfirm={() => {
                  this.setState({
                    isDeleteConfirm: true,
                    selectedId: volunteer._id,
                  });
                }}
                onShowVolunteerModalForm={() => {
                  this.setState({
                    isVolunteerDetail: true,
                    selectedId: volunteer._id,
                  });
                }}
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  nama,
  photo,
  status,
  onShowDeleteConfirm,
  openImage,
  onShowVolunteerModalForm,
  userStatus,
  isFromVolunteer,
  onShowUserModalDetail,
}: any) {
  let statusColor =
    status == "offline"
      ? "bg-danger"
      : status == "online"
      ? "bg-success"
      : "bg-info";

  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <span
            onClick={() => openImage(photo)}
            className="avatar rounded-circle mr-3 cursor-pointer"
          >
            <img
              src={
                photo
                  ? photo
                  : "https://api.virtualpoisoncenter.id/v1/images/icon/user.png"
              }
            />
          </span>
          <div className="media-body">
            <span className="mb-0 text-sm">{nama}</span>
          </div>
        </div>
      </th>
      <td>
        <span className="badge badge-dot mr-4">
          <i className={statusColor} />
          {status}
        </span>
      </td>
      <td>
        <Button color="warning" size="sm" onClick={onShowVolunteerModalForm}>
          Edit
        </Button>
        <Button color="danger" size="sm" onClick={onShowDeleteConfirm}>
          Hapus
        </Button>
        {isFromVolunteer && userStatus === "accepted" && (
          <Button color="primary" size="sm" onClick={onShowUserModalDetail}>
            Detail
          </Button>
        )}
      </td>
    </tr>
  );
}

export { VolunteerDataTable };
