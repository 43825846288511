import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import stein from "../../../api/stein";
import View from "./view";

import { toast } from "react-toastify";
import { ToastMessage } from "../../../components/ToastMessage";
import { v4 as uuidv4 } from "uuid";

const emptyData = {
  faskes_id: null,
  faskes_name: null,
  donatur_id: null,
  donatur_name: null,
  uang: null,
  barang: null
};

const Handler = ({ toggleModal, reload, isOpen }) => {
  let source = useRef(null);

  const [data, setData] = useState(emptyData);
  const [listFakses, setFaskesList] = useState([]);
  const [listDonatur, setDonaturList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isFaskesLoading, setFaskesLoading] = useState(false);
  const [isDonaturLoading, setDonaturLoading] = useState(false);

  const _handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value
    });
  };

  const _handleSelect = {
    donatur: opt => {
      setData({
        ...data,
        donatur_id: opt.value,
        donatur_name: opt.name
      });
    },
    faskes: opt => {
      setData({
        ...data,
        faskes_id: opt.value,
        faskes_name: opt.name
      });
    }
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);
      data.id = uuidv4();
      data.timestamp = new Date().toISOString();
      await stein.riwayat.create(data);
      toast.success(
        <ToastMessage icon="fa-check" text="Data Berhasil diinput" />
      );
      reload();
      setLoading(false);
      toggleModal();
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _loadFaskes = async () => {
      source.current = axios.CancelToken.source();
      try {
        setFaskesLoading(true);
        const rsp = await stein.faskes.list();
        setFaskesList(_construct.faskes(rsp));
        setFaskesLoading(false);
      } catch (error) {
        console.error("error: ", error);
      }
    };

    const _loadDonatur = async () => {
      source.current = axios.CancelToken.source();
      try {
        setDonaturLoading(true);
        const rsp = await stein.donatur.list();
        setDonaturList(_construct.donatur(rsp));
        setDonaturLoading(false);
      } catch (error) {
        console.error("error: ", error);
      }
    };

    _loadDonatur();
    _loadFaskes();

    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);

  return (
    <View
      listFakses={listFakses}
      listDonatur={listDonatur}
      isOpen={isOpen}
      isFaskesLoading={isFaskesLoading}
      isDonaturLoading={isDonaturLoading}
      isLoading={isLoading}
      onCancel={toggleModal}
      onOk={_onSubmit}
      onChange={_handleChange}
      data={data}
      onSelect={_handleSelect}
    />
  );
};

export default Handler;

const _construct = {
  faskes: data => {
    let options = [];
    for (let x = 0; x < data.length; x++) {
      const option = {
        label: `${data[x].nama_faskes} | ${data[x].provinsi}`,
        value: data[x].id,
        name: data[x].nama_faskes
      };
      options.push(option);
    }
    return options;
  },
  donatur: data => {
    let options = [];
    for (let x = 0; x < data.length; x++) {
      const option = {
        label: `${data[x]["Nama Lengkap"]} | ${data[x]["Nomor HP"]}`,
        value: data[x].id,
        name: data[x]["Nama Lengkap"]
      };
      options.push(option);
    }
    return options;
  }
};
