import React from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import { observer, inject } from "mobx-react";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";
import { axios } from "../api/config";
import { ICommunityStore } from "../stores/communityStore";
import { toJS } from "mobx";

interface CommunityModalFormProps {
  /** Modal visibility */
  isOpen?: boolean;
  /** Selected ID, used to get data on EDIT mode */
  updateId?: string;
  /** Title of the modal */
  title: string;
  /** On success form submit callback */
  onSuccess: () => any;
  /** Handles modal close, should change isOpen props from parent component */
  onClosed: () => any;
}

interface InjectedProps extends CommunityModalFormProps {
  communityStore: ICommunityStore;
}

@inject("communityStore")
@observer
class CommunityModalForm extends React.Component<CommunityModalFormProps, {}> {
  _source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this._handleChange = this._handleChange.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._removeMember = this._removeMember.bind(this);
    this._toggleDelete = this._toggleDelete.bind(this);
    this._selectFile = this._selectFile.bind(this);
    this._loadData = this._loadData.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  async _removeMember(e: React.FormEvent) {
    e.preventDefault();
    const {
      removeMember,
      getCommunity,
      selectedCommunity
    } = this.injected.communityStore;
    await removeMember(this._source.token);
    getCommunity(this._source.token, selectedCommunity._id);
    toast.success(
      <ToastMessage icon="fa-check" text="Member berhasil dihapus" />
    );
  }

  _selectFile(e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    const { files } = e.target;
    if (files) {
      this.injected.communityStore.setPhoto(files[0]);
    }
  }

  _handleChange(e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    const { name, value } = e.currentTarget;
    const { updateSelectedCommunity } = this.injected.communityStore;
    updateSelectedCommunity(name, value);
  }

  _toggleDelete(e: React.FormEvent, value?: any) {
    e.preventDefault();
    this.injected.communityStore.toggleDelete();
    if (value) {
      this.injected.communityStore.selectMember(value);
    }
  }

  async _handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { create, update, reset, updateData } = this.injected.communityStore;

    const { onSuccess, updateId } = this.props;
    this.setState({ isLoading: true });

    try {
      if (updateId) {
        await update(this._source.token);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil diedit" />
        );
      } else {
        await create(this._source.token);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil ditambahkan" />
        );
        reset();
      }

      if (typeof onSuccess === "function") {
        onSuccess();
        this.props.onClosed();
      }
    } catch (error) {
      updateData("isError", true);
    }
  }

  _handleToggle() {
    if (!this.injected.communityStore.isLoading) {
      this.props.onClosed();
    }
  }

  async _loadData() {
    const { getCommunity } = this.injected.communityStore;

    getCommunity(this._source.token, this.props.updateId || "");
  }

  componentDidUpdate(prevProps: CommunityModalFormProps) {
    const { reset } = this.injected.communityStore;

    // if modal is dismissed, then reset field states
    if (prevProps.isOpen && !this.props.isOpen) {
      reset();
    }

    // if modal is shown and on EDIT mode, then fetch data based on updateId
    if (this.props.isOpen && !prevProps.updateId && this.props.updateId) {
      this._loadData();
    }
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { isOpen, title, updateId } = this.props;
    const {
      isDelete,
      isError,
      isLoading,
      selectedCommunity,
      toggleDelete
    } = this.injected.communityStore;
    const { name, description, members, photo } = selectedCommunity;

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>
          {title} : {updateId ? "EDIT" : "TAMBAH"}
        </ModalHeader>
        <ModalBody>
          {isError && (
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          )}

          <Form onSubmit={this._handleSubmit}>
            {updateId ? (
              <img className="avatar rounded-circle" src={photo} alt="" />
            ) : null}
            <FormGroup>
              <Label for="name">Nama</Label>
              <Input
                defaultValue={name}
                disabled={isLoading}
                id="name"
                name="name"
                placeholder="Masukkan nama"
                required={true}
                type="text"
                onChange={this._handleChange}
              />
            </FormGroup>

            {updateId ? null : (
              <FormGroup>
                <Label for="photo">Foto</Label>
                <Input
                  onChange={this._selectFile}
                  type="file"
                  name="file"
                  id="photo"
                  required={true}
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label for="description">Deskrispi</Label>
              <Input
                value={description}
                disabled={isLoading}
                id="description"
                name="description"
                type="textarea"
                onChange={this._handleChange}
              />
            </FormGroup>
            {members.length > 0 ? (
              <FormGroup>
                <Label>Member</Label>
                <Table>
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Hapus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map(value => (
                      <tr key={value._id}>
                        <td>{value.full_name}</td>
                        <td>
                          <Button
                            size="sm"
                            onClick={e => this._toggleDelete(e, value)}
                            color="danger"
                          >
                            Hapus
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FormGroup>
            ) : null}

            <Button type="submit" color="primary" disabled={isLoading}>
              Submit
            </Button>

            <Button
              type="button"
              color="default"
              disabled={isLoading}
              onClick={this._handleToggle}
            >
              Batal
            </Button>

            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}
          </Form>

          <Modal isOpen={isDelete} toggle={toggleDelete}>
            <ModalHeader>Hapus Member</ModalHeader>
            <ModalBody>Anda yakin akan menghapus member </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this._toggleDelete}>
                Tidak
              </Button>
              <Button color="danger" onClick={this._removeMember}>
                Ya
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
      </Modal>
    );
  }
}

export { CommunityModalForm };
