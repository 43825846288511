import SteinStore from "stein-js-client";

const store = {
  faskes: new SteinStore(process.env.REACT_APP_DB_FASKES),
  donatur: new SteinStore(process.env.REACT_APP_DB_DONATUR),
  riwayat: new SteinStore(process.env.REACT_APP_DB_RIWAYAT_DONASI)
};

const authentication = {
  username: "kreki",
  password: "sijarisakti"
};

const faskes = {
  list: () => store.faskes.read("faskes", { authentication })
};

const donatur = {
  list: () => store.donatur.read("Form Responses 1", { authentication })
};

const riwayat = {
  list: () => store.riwayat.read("riwayat", { authentication }),
  create: payload =>
    store.riwayat.append("riwayat", [payload], { authentication }),
  update: (uuid, payload) =>
    store.riwayat.edit("riwayat", {
      search: { uuid },
      set: payload,
      authentication
    }),
  delete: uuid =>
    store.riwayat.delete("riwayat", { search: { uuid }, authentication })
};

export default {
  faskes,
  donatur,
  riwayat
};
