import * as React from "react";
import { CardSummary } from "./CardSummary";
import { LoadingIcon } from "./LoadingIcon";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { IDashboardStore } from "../stores/dashboardStore";

interface Props { }

interface InjectedProps extends Props {
  dashboardStore: IDashboardStore;
}

@inject("dashboardStore")
@observer
class DashboardSummary extends React.Component<Props, {}> {
  _source = axios.CancelToken.source();
  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    const { getSummary } = this.injected.dashboardStore;
    getSummary(this._source.token);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const {
      isError,
      isLoading,
      totalIncidents,
      responseTime,
      totals,
      totalResponse
    } = this.injected.dashboardStore.dashboardSummary;

    return isLoading ? (
      <LoadingIcon />
    ) : isError ? (
      <div className="alert alert-warning" role="alert">
        <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data summary
      </div>
    ) : (
      <div className="row">
        <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-danger"
            iconName="fas fa-chart-bar"
            title="Total Insiden"
            value={totalIncidents || "-"}
          />
        </div>
        <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-info"
            iconName="fas fa-clock"
            title="Response Time"
            value={`${responseTime || "-"} detik`}
          />
        </div>
        {/* <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-yellow"
            iconName="ni ni-user-run"
            title="Total Relawan"
            value={`${totals.volunteer || "-"}`}
          />
        </div> */}
        <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-info"
            iconName="ni ni-single-02"
            title="Total Pengguna"
            value={`${totals.user || "-"}`}
          />
        </div>
        <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-cyan"
            iconName="fas fa-hospital-alt"
            title="Total RS & Faskes"
            value={`${totals.hospital || "-"}`}
          />
        </div>
        <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-cyan"
            iconName="fas fa-hospital-alt"
            title="RS & Faskes Merespon"
            value={`${totalResponse.hospital || "-"}`}
          />
        </div>
        {/* <div className="col-xl-3 col-lg-6 mb-xl-4">
          <CardSummary
            iconColor="bg-cyan"
            iconName="fas fa-hospital-alt"
            title="Ambulan merespon"
            value={`${totalResponse.ambulance || "-"}`}
          />
        </div> */}
      </div>
    );
  }
}

export { DashboardSummary };
