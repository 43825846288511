import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { AmbulanceModalForm } from "./AmbulanceModalForm";
import { DeleteConfirm } from "./DeleteConfirm";

interface AmbulanceDataTableProps {
  isLoading: boolean;
  rows: Array<any>;
  currentPage: number;
  totalPage: number;
  loadData: () => any;
  onSearch: (value: string) => any;
}

interface AmbulanceDataTableState {
  isDeleteConfirm: boolean;
  isModalFormOpen: boolean;
  selectedId: string;
}

class AmbulanceDataTable extends React.Component<
  AmbulanceDataTableProps,
  AmbulanceDataTableState
> {
  constructor(props: any) {
    super(props);

    this._search = this._search.bind(this);
  }
  state = {
    isDeleteConfirm: false,
    isModalFormOpen: false,
    selectedId: ""
  };

  _search(value: string) {
    const { loadData, onSearch } = this.props;
    if (value === "") {
      loadData();
    } else {
      onSearch(value);
    }
  }

  render() {
    const { isLoading, loadData, rows, currentPage, totalPage } = this.props;
    const { isDeleteConfirm, isModalFormOpen, selectedId } = this.state;

    return (
      <React.Fragment>
        <AmbulanceModalForm
          isOpen={isModalFormOpen}
          updateId={selectedId}
          title="Ambulan"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isModalFormOpen: false, selectedId: "" });
          }}
        />
        <DeleteConfirm
          isOpen={isDeleteConfirm}
          deleteId={selectedId}
          deleteType="place"
          title="Apakah Anda yakin akan menghapus item?"
          onSuccess={loadData}
          onClosed={() => {
            this.setState({ isDeleteConfirm: false, selectedId: "" });
          }}
        />
        <DataTable2
          headings={["NAMA", "TELEPON", ""]}
          isLoading={isLoading}
          title="List Ambulan"
          onSearch={this._search}
          currentPage={currentPage}
          totalPages={totalPage}
          onPaginate={loadData}
          renderRows={() =>
            rows.map((ambulance: any) => (
              <DataTableRow
                key={ambulance._id}
                id={ambulance._id}
                name={ambulance.full_name}
                telepon={ambulance.phone_number}
                onSuccess={loadData}
                onShowDeleteConfirm={() => {
                  this.setState({
                    isDeleteConfirm: true,
                    selectedId: ambulance._id
                  });
                }}
                onShowModalForm={() => {
                  this.setState({
                    isModalFormOpen: true,
                    selectedId: ambulance._id
                  });
                }}
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  name,
  telepon,
  onShowDeleteConfirm,
  onShowModalForm
}: any) {
  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <div className="media-body">
            <span className="mb-0 text-sm">{name}</span>
          </div>
        </div>
      </th>
      <td>
        <span className="badge badge-dot mr-4">{telepon}</span>
      </td>
      <td>
        <Button color="warning" size="sm" onClick={onShowModalForm}>
          Edit
        </Button>
        <Button color="danger" size="sm" onClick={onShowDeleteConfirm}>
          Hapus
        </Button>
      </td>
    </tr>
  );
}

export { AmbulanceDataTable };
