import axios from "axios";
import { observable, action, set, toJS } from "mobx";
import { c } from "../constant";
import { getSummary as fetchSummary } from "../api/summary";
import {
  getEmergencies as fetchEmergencies,
  getActiveEmergencies as fetchActiveEmergencies
} from "../api/emergency";

interface totals {
  volunteer: number;
  hospital: number;
  police: number;
  firefighter: number;
  bpbd: number;
  user: number;
}

interface IDashboardSummary {
  isError: boolean;
  isLoading: boolean;
  totalIncidents: number;
  responseTime: number;
  totals: totals;
  totalResponse: {
    ambulance: number;
    hospital: number;
  };
}

interface IDashboard {
  isError: boolean;
  isLoading: boolean;
  emergencies: Array<any>;
}

export interface IDashboardStore {
  dashboard: IDashboard;
  dashboardSummary: IDashboardSummary;
  _source: any;
  setEmergencies(val: any): void;
  setTotalIncidents(val: number): void;
  setResponseTime(val: number): void;
  setTotals(val: totals): void;
  getSummary(token: any): void;
}

export class DashboardStore {
  pagination = {
    size: 5000,
    page: 1
  };

  @observable
  _source: any = axios.CancelToken.source();

  @observable
  dashboard: IDashboard = {
    isError: false,
    isLoading: false,
    emergencies: []
  };

  @observable
  dashboardSummary: IDashboardSummary = {
    isError: false,
    isLoading: false,
    totalIncidents: 0,
    responseTime: 0,
    totals: {
      volunteer: 0,
      hospital: 0,
      police: 0,
      firefighter: 0,
      bpbd: 0,
      user: 0
    },
    totalResponse: {
      ambulance: 0,
      hospital: 0
    }
  };

  @action
  updateDashboard = (key: string, value: any) => {
    set(this.dashboard, key, value);
  };

  @action
  updateDashboardSummary = (key: string, value: any) => {
    set(this.dashboardSummary, key, value);
  };

  consoleLog = (name: string, data: any) => {
    console.group(name);
    console.log(toJS(data));
    console.groupEnd();
  };

  @action
  getSummary = (token: any) => {
    const { updateDashboardSummary: update } = this;

    update(c.key.isLoading, true);
    return fetchSummary(token)
      .then(({ data }) => {
        update(c.key.isError, false);
        update(c.key.responseTime, data.response_time);
        update(c.key.totals, data.total_data);
        update(c.key.totalIncidents, data.incident.total);
        update(c.key.totalResponse, data.total_response);
      })
      .catch(() => update(c.key.isError, true))
      .then(() => update(c.key.isLoading, false));
  };
}

export default new DashboardStore();
