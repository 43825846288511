import request from "../utils/request";

const useAuth = true;

const Community = {
  get: (cancelToken: any, page: number = 1, size: number = 1000) =>
    request(
      {
        url: `/community?size=${size}&page=${page}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    ),
  getByID: (cancelToken: any, id: string) =>
    request(
      {
        url: `/community/${id}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    ),
  search: (cancelToken: any, name: string) =>
    request(
      {
        url: `/community/search`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          name
        }
      },
      useAuth
    ),
  create: (cancelToken: any, data: any) =>
    request(
      {
        url: `/community`,
        method: "POST",
        cancelToken: cancelToken,
        data
      },
      useAuth
    ),
  update: (cancelToken: any, data: any) =>
    request(
      {
        url: `/community/${data._id}`,
        method: "PATCH",
        cancelToken: cancelToken,
        data
      },
      useAuth
    ),
  removeMember: (cancelToken: any, id_community: string, id_user: string) =>
    request(
      {
        url: `/community/remove_member`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          id_community,
          id_user
        }
      },
      useAuth
    ),
  delete: (id: string) =>
    request(
      {
        url: `/community/${id}`,
        method: "DELETE"
      },
      useAuth
    )
};

const User = {
  listByFormVolunteer: (
    cancelToken: any,
    size: number,
    page: number,
    status?: string
  ) =>
    request(
      {
        url: `/users/form_volunteer?size=${size}&page=${page}&status=${status}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    ),
  searchByFormVolunteer: (cancelToken: any, value: string, status: string) =>
    request(
      {
        url: `/users/form_volunteer/search`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          full_name: value,
          status
        }
      },
      useAuth
    ),
  getByID: (cancelToken: any, id: string) =>
    request(
      {
        url: `/users/${id}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    ),
  changeStatus: (cancelToken: any, data: any) =>
    request(
      {
        url: `/form_volunteer/status`,
        method: "POST",
        cancelToken: cancelToken,
        data
      },
      useAuth
    ),
  search: (cancelToken: any, full_name: string) =>
    request(
      {
        url: `/users/search`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          full_name
        }
      },
      useAuth
    ),
  searchByHeroCategory: (cancelToken: any, heroId: string, full_name: string) =>
    request(
      {
        url: `/users/search`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          full_name,
          category: heroId
        }
      },
      useAuth
    ),
  getUsersByHeroCategory: (
    cancelToken: any,
    heroId: string,
    size: number,
    page: number
  ) =>
    request(
      {
        url: `/users/category/${heroId}?size=${size}&page=${page}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    )
};

const Place = {
  list: (
    cancelToken: any,
    placeId: string,
    page: number = 1,
    size: number = 1000
  ) =>
    request(
      {
        url: `/places/category/${placeId}?size=${size}&page=${page}`,
        method: "GET",
        cancelToken: cancelToken
      },
      useAuth
    ),
  search: (cancelToken: any, name: string) =>
    request(
      {
        url: `/places/search`,
        method: "POST",
        cancelToken: cancelToken,
        data: {
          name
        }
      },
      useAuth
    )
};

export default {
  Community,
  Place,
  User
};
