import React, { useEffect, useRef } from "react";
import View from "./donation-view";
import axios from "axios";
import stein from "../../api/stein";

const Controller = () => {
  let source = useRef(null);
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [reloadCount, setReloadCount] = React.useState(false);

  const _reload = () => {
    setReloadCount(reloadCount + 1);
  };

  useEffect(() => {
    const _loadData = async () => {
      source.current = axios.CancelToken.source();
      try {
        setLoading(true);
        const data = await stein.riwayat.list();
        setData(data);
        setLoading(false);
      } catch (error) {
        console.error("error: ", error);
      }
    };
    _loadData();
  }, [reloadCount]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);

  return <View isLoading={isLoading} data={data} reload={_reload} />;
};

export default Controller;
