import { HEROES_ID, HeroType, axios } from "./config";
import { auth } from "./auth";

async function getPlaces(
  cancelToken: any,
  pagination: { size: number; page: number },
  place?: HeroType
) {
  const placeId = place ? HEROES_ID[place] : "";
  try {
    const { data } = await axios.get(
      `/places/category/${placeId}?` +
        `size=${pagination.size}` +
        `&page=${pagination.page}`,
      {
        cancelToken: cancelToken,
        headers: {
          "X-Access-Token": auth.getToken(),
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

async function getProvincies(cancelToken: any) {
  try {
    const { data } = await axios.get(`/places/provinces`, {
      cancelToken: cancelToken,
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}
async function getCitites(cancelToken: any, provinceId: string) {
  try {
    const { data } = await axios.get(`/places/city/${provinceId}`, {
      cancelToken: cancelToken,
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

async function getNearby(
  cancelToken: any,
  reference: { lat: number; lng: number; radius?: number },
  pagination: { size: number; page: number },
  hero?: HeroType
) {
  const heroId = hero ? HEROES_ID[hero] : "";
  try {
    const { data } = await axios.get(
      `places/nearby?` +
        `lat=${reference.lat}&` +
        `lng=${reference.lng}&` +
        `radius=${reference.radius}&` +
        `size=${pagination.size}&` +
        `page=${pagination.page}&` +
        `category=${heroId}`,
      {
        cancelToken: cancelToken,
        headers: {
          "X-Access-Token": auth.getToken(),
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

async function getGeocode(address: string) {
  try {
    const { data } = await axios.get(
      `/places/gmaps/geocode?address=${address}`,
      {
        headers: {
          "X-Access-Token": auth.getToken(),
        },
      }
    );
    return data[0];
  } catch (error) {
    return false;
  }
}

function addPlace(place: HeroType, lat: number, lon: number, data: any) {
  return axios.post(
    "/places",
    {
      data: data,
      lat: lat,
      lon: lon,
      category: HEROES_ID[place],
    },
    {
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    }
  );
}

function updatePlace(
  id: string,
  lat: number,
  lon: number,
  data: { address: string; name: string; phone: string }
) {
  return axios.patch(
    `/places/${id}`,
    {
      data: data,
      location: {
        type: "Point",
        coordinates: [lon, lat],
      },
    },
    {
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    }
  );
}

function deletePlace(id: string) {
  return axios.delete(`/places/${id}`, {
    headers: {
      "X-Access-Token": auth.getToken(),
    },
  });
}

function getPlace(id: string) {
  return axios.get(`/places/${id}`, {
    headers: {
      "X-Access-Token": auth.getToken(),
    },
  });
}

export {
  addPlace,
  deletePlace,
  getGeocode,
  getNearby,
  getPlace,
  getPlaces,
  getProvincies,
  getCitites,
  updatePlace,
};
