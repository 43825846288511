import * as React from "react";
import { Map, MarkerAttributes } from "../components/Map";
import { VolunteerDataTable } from "../components/VolunteerDataTable";
import { PlaceSummary } from "../components/PlaceSummary";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { IVolunteerStore } from "../stores/volunteerStore";
import { c, heroTypes } from "../constant";

import IconHelpMarker from "../img/icons/marker/loc_me.svg";

interface VolunteerState {
  isError: boolean;
  isLoading: boolean;
  volunteers: Array<any>;
}

interface Props {}

interface InjectedProps extends Props {
  volunteerStore: IVolunteerStore;
}

@inject("volunteerStore")
@observer
class Volunteer extends React.Component<Props, VolunteerState> {
  _source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._search = this._search.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData(page?: number) {
    const { getVolunteers } = this.injected.volunteerStore;
    getVolunteers(this._source.token, page);
  }

  _search(value: string) {
    this.injected.volunteerStore.search(this._source.token, value);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const {
      isError,
      isLoading,
      volunteers,
      pagination
    } = this.injected.volunteerStore;
    const markers: Array<MarkerAttributes> = volunteers.map(
      (volunteer: any) => ({
        icon: IconHelpMarker,
        lat: volunteer.location.coordinates[1],
        lng: volunteer.location.coordinates[0],
        infoWindow: `<h4>${volunteer.full_name}</h4> ${volunteer.phone_number ||
          "-"}`
      })
    );

    return (
      <React.Fragment>
        <div className="header bg-gradient-yellow pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body">
              <PlaceSummary
                placeTitle={c.title.relawan}
                placeType={heroTypes.volunteer}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}

          <hr className="my-3" />

          <div className="row">
            <div className="col">
              <Map markers={markers} />
            </div>
          </div>

          <hr className="my-3" />

          <div className="row">
            <div className="col">
              <VolunteerDataTable
                isLoading={isLoading}
                rows={volunteers}
                loadData={this._loadData}
                onSearch={this._search}
                currentPage={pagination.currentPage}
                totalPage={pagination.totalPage}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Volunteer };
