import API from "../api/API";
import axios from "axios";
import { c } from "../constant";
import { observable, action, set, toJS } from "mobx";
import { getSummary as fetchSummary } from "../api/summary";
import { HeroType, HEROES_ID } from "../api/config";

interface IPlace {
  isError: boolean;
  isLoading: boolean;
  places: Array<any>;
  currentPage: number;
  totalPage: number;
  summary: {
    total: {
      hero: number;
      place: number;
      emergency: number;
    };
    response_time: number;
  };
}

interface IVolunteer {
  isError: boolean;
  isLoading: boolean;
  places: Array<any>;
  summary: {
    total_hero: number;
    total_man: number;
    total_woman: number;
    total_place: number;
    response_time: number;
  };
}

export interface IPlaceStore {
  _source: any;
  hospital: IPlace;
  ambulance: IPlace;
  volunteer: IVolunteer;
  updateAmbulance(key: string, values: any): void;
  updateHospital(key: string, values: any): void;
  updateVolunteer(key: string, values: any): void;
  getSummary(token: any, type: HeroType): Promise<any>;
  getPlaces(
    token: any,
    type: HeroType,
    page?: number,
    size?: number
  ): Promise<any>;
  search(token: any, type: HeroType, value: string): Promise<any>;
  [key: string]: any;
}

export class PlaceStore implements IPlaceStore {
  @observable
  _source: any = axios.CancelToken.source();

  @observable
  ambulance = {
    isLoading: false,
    isError: false,
    places: [],
    currentPage: 1,
    totalPage: 1,
    summary: {
      total: {
        hero: 0,
        place: 0,
        emergency: 0
      },
      response_time: 0
    }
  };

  @observable
  hospital = {
    isLoading: false,
    isError: false,
    places: [],
    currentPage: 1,
    totalPage: 1,
    summary: {
      total: {
        hero: 0,
        place: 0,
        emergency: 0
      },
      response_time: 0
    }
  };

  @observable
  volunteer = {
    isLoading: false,
    isError: false,
    places: [],
    summary: {
      total_hero: 0,
      total_man: 0,
      total_woman: 0,
      total_place: 0,
      response_time: 0
    }
  };

  @action
  updateHospital = (key: string, value: any) => {
    set(this.hospital, key, value);
  };

  @action
  updateAmbulance = (key: string, value: any) => {
    set(this.ambulance, key, value);
  };

  @action
  updateVolunteer = (key: string, value: any) => {
    set(this.volunteer, key, value);
  };

  _getUpdate = (type: HeroType) => {
    const { updateHospital, updateVolunteer, updateAmbulance } = this;

    let update = updateHospital;

    switch (type) {
      case c.place.ambulance:
        update = updateAmbulance;
        break;
      case c.place.hospital:
        update = updateHospital;
        break;
      case c.place.volunteer:
        update = updateVolunteer;
        break;
      default:
        break;
    }
    return update;
  };

  @action
  search = (token: any, type: HeroType, value: string) => {
    let update = this._getUpdate(type);
    update(c.key.isLoading, true);

    return API.Place.search(token, value)
      .then((data: any) => {
        update(c.key.places, data.rows);
        update(c.key.isLoading, false);
      })
      .catch(() => update(c.key.isError, true));
  };

  getSummary = (token: any, type: HeroType) => {
    const { _getUpdate } = this;
    let update = _getUpdate(type);

    update(c.key.isLoading, true);
    update(c.key.isError, false);

    return fetchSummary(token, type)
      .then(({ data }) => {
        update(c.key.summary, data);
      })
      .catch(() => update(c.key.isError, true))
      .then(() => update(c.key.isLoading, false));
  };

  @action
  getPlaces = (
    token: any,
    type: HeroType,
    page: number = 1,
    size: number = 10
  ) => {
    const { _getUpdate } = this;
    let update = _getUpdate(type);
    const placeId = HEROES_ID[type];

    update("currentPage", page);
    update("isLoading", true);
    update("isError", false);

    return API.Place.list(token, placeId, page, size)
      .then((data: any) => {
        update("places", data.rows);
        update("currentPage", data.current_page);
        update("totalPage", data.last_page);
      })
      .catch(() => update(c.key.isError, true))
      .finally(() => update(c.key.isLoading, false));
  };
}

export default new PlaceStore();
