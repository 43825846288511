import { HEROES_ID, ROLES_ID, HeroType, RoleType, axios } from "./config";
import { auth } from "./auth";

export interface UserFields {
  address: string;
  category: HeroType;
  email: string;
  fullName: string;
  jobplaceId?: string;
  lat: number;
  lon: number;
  password: string;
  phoneNumber: string;
  role: RoleType;
  province?: string;
  city?: string;
}

async function getUsersByHeroCategory(cancelToken: any, hero: string) {
  const heroId = HEROES_ID[hero];
  try {
    const { data } = await axios.get(`/users/category/${heroId}?size=1000`, {
      cancelToken: cancelToken,
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

function getUser(id: string) {
  return axios.get(`/users/${id}`, {
    headers: {
      "X-Access-Token": auth.getToken(),
    },
  });
}

async function getUsers(
  cancelToken: any,
  size: number = 1000,
  page: number = 1
) {
  try {
    const { data } = await axios.get(`/users?size=${size}&page=${page}`, {
      cancelToken: cancelToken,
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

function createUser(data: UserFields, hero?: HeroType) {
  const heroId = hero ? HEROES_ID[hero] : "";

  return axios.post(
    "/users",
    {
      address: data.address,
      email: data.email,
      full_name: data.fullName,
      location: {
        type: "Point",
        coordinates: [data.lon, data.lat],
      },
      password: data.password,
      phone_number: data.phoneNumber,
      role_id: ROLES_ID[data.role],
      category: heroId,
      province: data.province,
      city: data.city,
    },
    {
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    }
  );
}

function updateUser(id: string, data: UserFields) {
  return axios.patch(
    `/users/${id}`,
    {
      address: data.address,
      email: data.email,
      full_name: data.fullName,
      location: {
        type: "Point",
        coordinates: [data.lon, data.lat],
      },
      password: data.password,
      phone_number: data.phoneNumber,
      jobplace_id: data.jobplaceId ? data.jobplaceId : null,
      category: HEROES_ID[data.category],
      role_id: { _id: ROLES_ID[data.role] },
    },
    {
      headers: {
        "X-Access-Token": auth.getToken(),
      },
    }
  );
}

function deleteUser(id: string) {
  return axios.delete(`/users/${id}`, {
    headers: {
      "X-Access-Token": auth.getToken(),
    },
  });
}

export {
  createUser,
  deleteUser,
  getUser,
  getUsers,
  getUsersByHeroCategory,
  updateUser,
};
