import * as React from "react";

import { GOOGLE_MAPS_API_KEY } from "../api/config";
import {
  GoogleApiWrapper,
  MapProps as GoogleMapsProps
} from "google-maps-react";

export type MarkerAttributes = {
  id?: string;
  icon: string;
  infoWindow: string;
  lat: number;
  lng: number;
};

type LatLng = {
  lat: number;
  lng: number;
};

interface MapProps extends GoogleMapsProps {
  center?: LatLng;
  markers: Array<MarkerAttributes>;
  zoom?: number;
}

interface MapState {
  firstMounted: boolean;
}

class GoogleMap extends React.Component<MapProps, MapState> {
  private _mapContainer: React.RefObject<HTMLDivElement>;
  static defaultProps = {
    center: { lat: -7.82284, lng: 112.011864 },
    zoom: 11
  };
  constructor(props: any) {
    super(props);
    this.state = {
      firstMounted: false
    };

    this._loadMap = this._loadMap.bind(this);
    this._mapContainer = React.createRef();
  }

  _loadMap() {
    const { center, google, markers, zoom } = this.props;
    const mapInstance = new google.maps.Map(this._mapContainer.current, {
      zoom: zoom,
      center: center
    });

    markers.map((point: MarkerAttributes) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(point.lat, point.lng),
        icon: point.icon,
        map: mapInstance
      });

      const infowindow = new google.maps.InfoWindow({
        content: point.infoWindow
      });

      marker.addListener("click", function() {
        infowindow.open(mapInstance, marker);
      });
    });
  }

  componentDidMount() {
    this.setState({ firstMounted: true });
  }

  componentDidUpdate(prevProps: MapProps) {
    this._loadMap();
  }

  componentWillUnmount() {
    this.setState({ firstMounted: false });
  }

  render() {
    const { loaded } = this.props;

    return loaded ? (
      <div
        style={{ height: "480px", width: "100%" }}
        ref={this._mapContainer}
      />
    ) : (
      <h1> Loading Google Maps API </h1>
    );
  }
}

export const Map = GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(GoogleMap);
