import React from "react";
import CurrencyFormat from "react-currency-format";
import Form from "./form";

import { Button, Table } from "reactstrap";

const View = ({ data, reload }) => {
  const [isFormVisible, setFormVisible] = React.useState(false);
  const _toggleForm = () => {
    setFormVisible(!isFormVisible);
  };
  return (
    <>
      <Form toggleModal={_toggleForm} isOpen={isFormVisible} reload={reload} />
      <div className="header bg-gradient-teal pb-8 pt-5 pt-md-8" />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card shadow">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Data Donasi</h3>
                  </div>
                  <div className="col text-right">
                    <Button color="primary" onClick={_toggleForm}>
                      Tambah
                    </Button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>Donatur</th>
                      <th>Tujuan (Faskes)</th>
                      <th>Uang</th>
                      <th>Barang</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => (
                      <tr key={item.id}>
                        <td>{item.donatur_name}</td>
                        <td>{item.faskes_name}</td>
                        <td>
                          <CurrencyFormat
                            value={item.uang}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp."}
                          />
                        </td>
                        <td>{item.barang}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
