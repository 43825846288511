import * as React from "react";
import { Input, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { LoadingIcon } from "./LoadingIcon";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

interface DataTableProps {
  currentPage: number;
  totalPages: number;
  headings: Array<string>;
  isLoading?: boolean;
  title: string;
  toolbar?: React.ReactNode;
  onPaginate: (page: number) => any;
  renderRows: () => Array<JSX.Element>;
  onSearch?: (text: string) => any;
}

interface DataTableState {
  isError: boolean;
  isLoading: boolean;
  searchText: string;
}

class DataTable2 extends React.Component<DataTableProps, DataTableState> {
  constructor(props: DataTableProps) {
    super(props);

    this.state = {
      isError: false,
      isLoading: this.props.isLoading ? this.props.isLoading : false,
      searchText: ""
    };
  }

  timer: any = null;

  static defaultProps = {};

  _createPaginationNav() {
    const { currentPage, totalPages, onPaginate } = this.props;

    let paginationNumbers: Array<any> = [];
    let isActive;

    paginationNumbers.push(
      <PaginationItem key={0} disabled={currentPage <= 1}>
        <PaginationLink
          tag="button"
          onClick={() => onPaginate(currentPage - 1)}
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
    )

    for (let i = 1; i <= Math.ceil(totalPages / 10); i++) {
      isActive = i == currentPage ? true : false;

      paginationNumbers.push(
        <PaginationItem key={i} active={isActive}>
          <PaginationLink tag="button" onClick={() => onPaginate(i)}>
            {i}
            {isActive && <span className="sr-only">current</span>}
          </PaginationLink>
        </PaginationItem>
      );
    }

    paginationNumbers.push(
      <PaginationItem
        key={totalPages + 1}
        disabled={currentPage == totalPages || totalPages == 0}
      >
        <PaginationLink
          tag="button"
          onClick={() => onPaginate(currentPage + 1)}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    );

    return paginationNumbers;
  }

  _handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange();
    }
  };

  _handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(this.timer);
    const { value } = e.currentTarget;
    this.setState({ searchText: value });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  triggerChange = () => {
    const { searchText } = this.state;
    const { onSearch } = this.props;
    if (typeof onSearch === "function") {
      onSearch(searchText);
    }
  };

  render() {
    const { searchText } = this.state;
    const {
      headings,
      isLoading,
      title,
      toolbar,
      renderRows,
      onSearch
    } = this.props;

    return (
      <div className="card shadow">
        <div className="card-header border-0">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="mb-0">
                {title}{" "}
                {isLoading && (
                  <LoadingIcon
                    stroke="#32325d"
                    style={{ width: "1.5em", height: "1.5em" }}
                  />
                )}
              </h3>
            </div>
            <div className="col text-right">
              {typeof onSearch === "function" && (
                <Input
                  type="search"
                  name="search"
                  bsSize="sm"
                  defaultValue={searchText}
                  onChange={this._handleChangeSearch}
                  onKeyDown={this._handleKeyDown}
                  placeholder="Cari ..."
                  className="w-50 d-inline-block mr-3"
                />
              )}
              {toolbar}
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                {headings.map((head, index) => (
                  <th scope="col" key={index}>
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </table>
        </div>
        <div className="card-footer py-4 overflow-x-auto">
          <Pagination aria-label="Table navigation">
            {this._createPaginationNav()}
          </Pagination>
        </div>
      </div>
    );
  }
}

export { DataTable2 };
