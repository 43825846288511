import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { UserModalForm } from "./UserModalForm";
import { CommunityModalForm } from "./CommunityModalForm";
import { DeleteConfirm } from "./DeleteConfirm";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { ICommunityStore } from "../stores/communityStore";
import { c, heroTypes } from "../constant";
import { toJS } from "mobx";

interface UserDataTableProps {
  /** Indicates loading from parent component */
  isLoading: boolean;
  /** Pagination: total rows per page */
  pageSize: number;
}

interface InjectedProps extends UserDataTableProps {
  communityStore: ICommunityStore;
}

@inject("communityStore")
@observer
class CommunityDataTable extends React.Component<UserDataTableProps, {}> {
  _source = axios.CancelToken.source();

  constructor(props: UserDataTableProps) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._search = this._search.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  public static defaultProps = {
    pageSize: 8
  };

  _search(value: string) {
    const { search } = this.injected.communityStore;
    if (value === "") {
      this._loadData();
    } else {
      search(this._source.token, value);
    }
  }

  _loadData(page?: number) {
    const { getCommunities } = this.injected.communityStore;
    getCommunities(this._source.token, page);
  }

  onClose(type?: string) {
    const { updateData } = this.injected.communityStore;

    updateData("selectedId", "");
    if (type === "delete") {
      updateData("isDeleteConfirm", false);
      return;
    }
    updateData("isModalForm", false);
  }

  onAdd() {
    const { updateData } = this.injected.communityStore;
    updateData("isModalForm", true);
  }

  onConfirm(id: number, type?: string) {
    const { updateData } = this.injected.communityStore;
    updateData("selectedId", id);
    if (type === "delete") {
      updateData("isDeleteConfirm", true);
      return;
    }
    updateData("isModalForm", true);
  }

  showDetail(id: string) {
    const { updateData } = this.injected.communityStore;
    updateData("selectedId", id);
    updateData("showDetail", true);
  }

  hideDetail() {
    const { updateData } = this.injected.communityStore;
    updateData("showDetail", false);
    updateData("selectedId", "");
  }

  componentDidMount() {
    this._loadData();
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { pageSize } = this.props;
    const {
      isLoading,
      isDeleteConfirm,
      isModalForm,
      selectedId,
      currentPage,
      totalPages,
      totalRows,
      rows
    } = this.injected.communityStore.data;

    return (
      <React.Fragment>
        <CommunityModalForm
          isOpen={isModalForm}
          updateId={selectedId}
          title="Form Komunitas"
          onSuccess={() => this._loadData(currentPage)}
          onClosed={() => this.onClose()}
        />
        <DeleteConfirm
          isOpen={isDeleteConfirm}
          deleteId={selectedId}
          deleteType="community"
          title="Apakah Anda yakin akan menghapus item?"
          onSuccess={() =>
            this._loadData(
              totalRows % pageSize == 1 ? currentPage - 1 : currentPage
            )
          }
          onClosed={() => {
            this.onClose("delete");
          }}
        />

        <DataTable2
          headings={["NAMA", "DESKRIPSI", ""]}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          onSearch={this._search}
          title="List Komunitas"
          toolbar={
            <Button color="primary" size="sm" onClick={() => this.onAdd()}>
              Tambah
            </Button>
          }
          onPaginate={this._loadData}
          renderRows={() =>
            rows.map((community: any) => (
              <DataTableRow
                key={community._id}
                name={community.name}
                description={community.description}
                photo={community.photo}
                onShowDeleteConfirm={() => {
                  this.onConfirm(community._id, "delete");
                }}
                onShowUserModalForm={() => {
                  this.onConfirm(community._id);
                }}
                onShowUserModalDetail={() => {
                  this.showDetail(community._id);
                }}
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  name,
  description,
  photo,
  onShowDeleteConfirm,
  onShowUserModalForm
}: any) {
  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <a href="#" className="avatar rounded-circle mr-3">
            <img alt="Foto" src={photo} />
          </a>
          <div className="media-body">
            <span className="mb-0 text-sm">{name}</span>
          </div>
        </div>
      </th>
      <td>{description}</td>
      <td>
        <Button color="warning" size="sm" onClick={onShowUserModalForm}>
          Edit
        </Button>
        <Button color="danger" size="sm" onClick={onShowDeleteConfirm}>
          Hapus
        </Button>
      </td>
    </tr>
  );
}

export { CommunityDataTable };
