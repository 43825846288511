import axios from "axios";
import { stringify } from "qs";

const TOKEN_KEYWORD = process.env.REACT_APP_TOKEN_KEYWORD || "";
const BASE_URL = process.env.REACT_APP_BASE_URL || "";
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || "";
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export type HeroType =
  | "ambulance"
  | "hospital"
  | "police"
  | "firefighter"
  | "bpbd"
  | "volunteer"
  | "user";

export type RoleType =
  | "institution"
  | "superadmin"
  | "user"
  | "volunteer"
  | "admin";

const HEROES_ID: { [key: string]: string } = {
  hospital: "5c20fe3c31a5dd349c88e631",
  police: "5c1fabb6fec2cf6fa1b0b7f2",
  firefighter: "5c1fabbafec2cf6fa1b0b7f3",
  bpbd: "5c20575cc8d6abc7a31f42dc",
  volunteer: "5c20fe3c31a5dd349c88e631",
  user: "5c51b7095ac5a14ed0f02a60",
  ambulance: "5d05a7c6017cb62130cc74b1",
};

const ROLES_ID: { [key: string]: string } = {
  institution: "5c22634e5ef2e348c43d0a0f",
  superadmin: "5bfb994565fefd4220c6fdc8",
  admin: "5fc44e678ba69e16d4454ab0",
  user: "5bfc00ba7c385079245624de",
  volunteer: "5c22624d5ef2e348c43d0a08",
};

axios.defaults.baseURL = BASE_URL;

export {
  BASE_URL,
  GOOGLE_MAPS_API_KEY,
  HEROES_ID,
  ROLES_ID,
  TOKEN_KEYWORD,
  WS_BASE_URL,
  axios,
  stringify,
};
