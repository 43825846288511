import * as React from "react";
import { CommunityDataTable } from "../components/CommunityDataTable";

interface UserState {
  isError: boolean;
  isLoading: boolean;
}

class Community extends React.Component<{}, UserState> {
  constructor(props: any) {
    super(props);

    this.state = { isError: false, isLoading: false };
  }

  render() {
    const { isError, isLoading } = this.state;

    return (
      <React.Fragment>
        <div className="header bg-gradient-teal pb-8 pt-5 pt-md-8" />

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}
          <div className="row">
            <div className="col">
              <CommunityDataTable isLoading={isLoading} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Community };
