import axios from "axios";
import { c } from "../constant";
import { observable, action, toJS } from "mobx";
import { HEROES_ID } from "../api/config";
import API from "../api/API";

export interface IVolunteerStore {
  _source: any;
  isError: boolean;
  isLoading: boolean;
  volunteers: Array<any>;
  pagination: {
    currentPage: number;
    totalPage: number;
  };
  addVolunteers(value: any): void;
  search(token: any, value: string): Promise<any>;
  getVolunteers(token: any, page?: number, size?: number): void;
}

const VolunteerHeroId = HEROES_ID[c.component.volunteer];

export class VolunteerStore implements IVolunteerStore {
  @observable
  _source: any = axios.CancelToken.source();

  @observable
  isLoading = false;

  @observable
  isError = false;

  @observable
  volunteers: Array<any> = [];

  @observable
  pagination = {
    currentPage: 1,
    totalPage: 0
  };

  @action
  updateVolunteers = (values: Array<any>) => {
    this.volunteers = values;
  };

  @action
  addVolunteers = (value: any) => {
    this.volunteers.push(value);
  };

  @action
  getVolunteers = (token: any, page: number = 1, size: number = 10) => {
    const { updateVolunteers } = this;
    this.isLoading = true;
    this.pagination.currentPage = page;

    return API.User.getUsersByHeroCategory(token, VolunteerHeroId, size, page)
      .then((data: any) => {
        updateVolunteers(data.rows);
        this.isLoading = false;
        this.pagination.totalPage = data.last_page;
      })
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false));
  };

  @action
  search = (token: any, value: string) => {
    const { updateVolunteers } = this;
    this.isLoading = true;
    return API.User.searchByHeroCategory(token, VolunteerHeroId, value)
      .then((data: any) => {
        updateVolunteers(data.rows);
      })
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false));
  };
}

export default new VolunteerStore();
