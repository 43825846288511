import * as React from "react";

interface CardSummaryProps {
  /** Icon's color class name */
  iconColor: string;
  /** Icon's type class name */
  iconName: string;
  /** Title of the summary card */
  title: string;
  /** Value of the summary card */
  value: number | string;
}

function CardSummary({ title, value, iconColor, iconName }: CardSummaryProps) {
  return (
    <div className="card card-stats mb-4 mb-xl-0">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title text-uppercase text-muted mb-0">
              {title}
            </h5>
            <span className="h2 font-weight-bold mb-0">{value}</span>
          </div>
          <div className="col-auto">
            <div
              className={
                "icon icon-shape text-white rounded-circle shadow " + iconColor
              }
            >
              <i className={iconName} />
            </div>
          </div>
        </div>
        {/* <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-success mr-2">
            <i className="fa fa-arrow-up" /> 3.48%
          </span>
          <span className="text-nowrap">Since last month</span>
        </p> */}
      </div>
    </div>
  );
}

export { CardSummary };
