import React from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";

import { getPlace, addPlace, updatePlace } from "../api/places";
import { HeroType } from "../api/config";

interface ModalPlaceFormProps {
  isOpen: boolean;
  updateId?: string;
  placeType: HeroType;
  title: string;
  onSuccess: () => any;
  onClosed: () => any;
}

interface ModalPlaceFormState {
  fields: PlaceFields;
  isError: boolean;
  isLoading: boolean;
}

type PlaceFields = {
  address: string;
  latitude: number;
  longitude: number;
  name: string;
  phone: string;
  category?: string;
};

class ModalPlaceForm extends React.Component<
  ModalPlaceFormProps,
  ModalPlaceFormState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      fields: {
        address: "",
        latitude: 0,
        longitude: 0,
        name: "",
        phone: "",
        category: ""
      },
      isError: false,
      isLoading: false
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    console.log("name, value: ", name, value);
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }));
  }

  async _handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const {
      address,
      latitude,
      longitude,
      name,
      phone,
      category
    } = this.state.fields;
    const { onSuccess, placeType, updateId } = this.props;
    let data: any = null;

    if (!address && !latitude && !longitude && !name && !phone) {
      return false;
    }

    this.setState({ isLoading: true });

    if (placeType === "hospital") {
      data = {
        address,
        name,
        phone,
        category
      };
    } else {
      data = {
        address,
        name,
        phone
      };
    }

    try {
      if (updateId) {
        await updatePlace(updateId, latitude, longitude, data);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil diedit" />
        );
      } else {
        await addPlace(placeType, latitude, longitude, data);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil ditambahkan" />
        );
        this.setState({
          fields: {
            address: "",
            latitude: 0,
            longitude: 0,
            name: "",
            phone: "",
            category: ""
          }
        });
      }

      if (typeof onSuccess === "function") {
        onSuccess();
        this.props.onClosed();
      }
    } catch (error) {
      this.setState({ isError: true });
    }

    this.setState({
      isLoading: false
    });
  }

  _handleToggle() {
    if (!this.state.isLoading) {
      this.props.onClosed();
    }
  }

  componentDidUpdate(prevProps: ModalPlaceFormProps) {
    // if modal is dismissed
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        fields: {
          address: "",
          latitude: 0,
          longitude: 0,
          name: "",
          phone: "",
          category: ""
        },
        isError: false
      });
    }

    // if modal is on EDIT mode
    if (this.props.isOpen && !prevProps.updateId && this.props.updateId) {
      this.setState({ isLoading: true });

      getPlace(this.props.updateId)
        .then(response => {
          const data: any = response.data;
          this.setState({
            fields: {
              address: data.data.address || "",
              latitude: data.location.coordinates[1] || 0,
              longitude: data.location.coordinates[0] || 0,
              name: data.data.name || "",
              phone: data.data.phone || "",
              category: data.data.category || ""
            }
          });
        })
        .catch(() => this.setState({ isError: true }))
        .then(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { isOpen, title, updateId, placeType } = this.props;
    const { fields, isError, isLoading } = this.state;

    const RoomForm = (
      <FormGroup key={1}>
        <Label for="category">Kategori RS & Faskes</Label>
        <Input
          required={true}
          onChange={this._handleChange}
          disabled={isLoading}
          type="select"
          name="category"
          id="category"
          value={fields.category}
        >
          <option value="puskesmas_klinik">Puskesmas & Klinik</option>
          <option value="A">RS tipe A</option>
          <option value="B">RS tipe B</option>
          <option value="C">RS tipe C</option>
          <option value="D">RS tipe D</option>
        </Input>
      </FormGroup>
    );

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>
          {title} : {updateId ? "EDIT" : "TAMBAH"}
        </ModalHeader>
        <ModalBody>
          {isError && (
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          )}

          <Form onSubmit={this._handleSubmit}>
            <FormGroup>
              <Label for="name">Nama</Label>
              <Input
                disabled={isLoading}
                id="name"
                name="name"
                placeholder="Masukkan nama"
                required={true}
                type="text"
                value={fields.name}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Alamat</Label>
              <Input
                disabled={isLoading}
                id="address"
                name="address"
                placeholder="Masukkan alamat"
                required={true}
                type="text"
                value={fields.address}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Nomor telepon</Label>
              <Input
                disabled={isLoading}
                id="phone"
                name="phone"
                placeholder="Masukkan nomor telepon"
                required={true}
                type="text"
                value={fields.phone}
                onChange={this._handleChange}
              />
            </FormGroup>
            {placeType === "hospital" ? RoomForm : null}
            <FormGroup>
              <Label for="latitude">Latitude (LAT)</Label>
              <Input
                disabled={isLoading}
                id="latitude"
                name="latitude"
                placeholder="Masukkan latitude"
                required={true}
                type="number"
                value={fields.latitude}
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Latitude harus bernilai desimal. Contoh: -8.182
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="longitude">Longitude (LNG)</Label>
              <Input
                disabled={isLoading}
                id="longitude"
                name="longitude"
                placeholder="Masukkan longitude"
                required={true}
                type="number"
                value={fields.longitude}
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Longitude harus bernilai desimal. Contoh: 111.117
              </FormText>
            </FormGroup>

            <Button type="submit" color="primary" disabled={isLoading}>
              Submit
            </Button>

            <Button
              type="button"
              color="default"
              disabled={isLoading}
              onClick={this._handleToggle}
            >
              Batal
            </Button>

            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export { ModalPlaceForm };
