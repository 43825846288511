import React from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";

interface ModalConfirmProps {
  /** Indicates modal visibility */
  isOpen: boolean;
  /** Text shown inside modal */
  text: string;
  /** Success message */
  successMessage: string;

  /** Handles cancelation, should change isOpen props from parent component */
  onClosed: () => any;
  /** On confirmation ("YES" clicked) */
  onConfirmed?: () => any;
  /** On success (if onConfirmed() is success) */
  onSuccess?: () => any;
}

interface ModalConfirmState {
  isError: boolean;
  isLoading: boolean;
}

class ModalConfirm extends React.Component<
  ModalConfirmProps,
  ModalConfirmState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isError: false,
      isLoading: false
    };

    this._handleToggle = this._handleToggle.bind(this);
    this._handleConfirm = this._handleConfirm.bind(this);
  }

  async _handleConfirm(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { onClosed, onConfirmed, onSuccess, successMessage } = this.props;

    try {
      if (typeof onConfirmed == "function") {
        await onConfirmed();
        // this.setState({ isError: false });
        toast.success(<ToastMessage icon="fa-check" text={successMessage} />);
        if (typeof onSuccess == "function") onSuccess();
        onClosed();
      }
    } catch (err) {
      this.setState({ isError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  _handleToggle() {
    if (!this.state.isLoading) {
      this.props.onClosed();
    }
  }

  componentDidUpdate(prevProps: ModalConfirmProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isError: false });
    }
  }

  render() {
    const { isOpen, text } = this.props;
    const { isError, isLoading } = this.state;

    const ModalAction = (
      <ModalFooter>
        {isLoading && (
          <LoadingIcon
            stroke="black"
            style={{ width: "1.5em", height: "1.5em" }}
          />
        )}

        <Button
          color="primary"
          disabled={isError || isLoading}
          onClick={this._handleConfirm}
        >
          Ya
        </Button>
        <Button
          color="default"
          onClick={this._handleToggle}
          disabled={isError || isLoading}
        >
          Tidak
        </Button>
      </ModalFooter>
    );

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>{text}</ModalHeader>

        {isError && (
          <ModalBody>
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          </ModalBody>
        )}

        {!isError && ModalAction}
      </Modal>
    );
  }
}

export { ModalConfirm };
