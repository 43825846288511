import React from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { LoadingIcon } from "./LoadingIcon";
import CreatableSelect from "react-select/lib/Creatable";
import { axios } from "../api/config";

import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { IEmergencyStore } from "../stores/emergencyStore";

interface FinishEmergencyModalFormProps {
  updateId: string;
  isOpen?: boolean;
  // title: string;
  onClosed: () => any;
  onSuccess: () => any;
}

interface InjectedProps extends FinishEmergencyModalFormProps {
  emergencyStore: IEmergencyStore;
}

interface FinishEmergencyModalFormState {
  isError: boolean;
}

@inject("emergencyStore")
@observer
class FinishEmergencyModalForm extends React.Component<
  FinishEmergencyModalFormProps,
  FinishEmergencyModalFormState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isError: false
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleSelect = this._handleSelect.bind(this);
  }

  _source = axios.CancelToken.source();

  get injected() {
    return this.props as InjectedProps;
  }

  _handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.injected.emergencyStore.updateEmergencyConfirm(name, value);
  }

  async _handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.injected.emergencyStore
      .setEmergencyAction(this.props.updateId, "finish")
      .then(() => {
        this.props.onClosed();
        this.props.onSuccess();
      });
  }

  _handleToggle() {
    if (!this.injected.emergencyStore.isLoading) {
      this.props.onClosed();
    }
  }

  _handleSelect(inputValue: any) {
    const {
      updateSelectedCategory,
      updateEmergencyConfirm
    } = this.injected.emergencyStore;
    updateSelectedCategory(inputValue);
    if (!inputValue) {
      return;
    }
    updateEmergencyConfirm("categoryId", inputValue.value);
  }

  componentWillMount() {
    this.injected.emergencyStore.getCategories(this._source.token);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { isOpen } = this.props;
    const { isError } = this.state;
    const {
      categories,
      emergencyConfirm,
      createCategory,
      isCategoryLoading,
      isLoading,
      selectedCategory
    } = this.injected.emergencyStore;
    const { victimsTreated, victimsDied } = emergencyConfirm;

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>Konfirmasi</ModalHeader>
        <ModalBody>
          {isError && (
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          )}

          <Form onSubmit={this._handleSubmit}>
            <FormGroup>
              <Label for="fullName">Kategori Gawat Darurat</Label>
              <CreatableSelect
                isClearable
                onChange={this._handleSelect}
                onCreateOption={createCategory}
                options={toJS(categories)}
                isLoading={isCategoryLoading}
                value={selectedCategory}
              />
            </FormGroup>
            <FormGroup>
              <Label for="victimsTreated">Korban dirawat</Label>
              <Input
                disabled={isLoading}
                id="victimsTreated"
                name="victimsTreated"
                required={true}
                type="number"
                value={victimsTreated}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="victimsDied">Korban meninggal</Label>
              <Input
                disabled={isLoading}
                id="victimsDied"
                name="victimsDied"
                required={true}
                type="number"
                value={victimsDied}
                onChange={this._handleChange}
              />
            </FormGroup>

            <Button type="submit" color="primary" disabled={isLoading}>
              Selesai
            </Button>

            <Button
              type="button"
              color="default"
              disabled={isLoading}
              onClick={this._handleToggle}
            >
              Batal
            </Button>

            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export { FinishEmergencyModalForm };
