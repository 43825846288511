import { HeroType } from "./api/config";

interface HeroTypes {
  ambulance: HeroType;
  bpbd: HeroType;
  firefighter: HeroType;
  hospital: HeroType;
  police: HeroType;
  user: HeroType;
  volunteer: HeroType;
}

export const c = {
  key: {
    bpbd: "bpbd",
    currentPage: "currentPage",
    firefighter: "firefighter",
    hospital: "hospital",
    isConfirmCancel: "isConfirmCancel",
    isConfirmFinish: "isConfirmFinish",
    isError: "isError",
    isLoading: "isLoading",
    places: "places",
    police: "police",
    responseTime: "responseTime",
    rows: "rows",
    selectedName: "selectedName",
    selectedId: "selectedId",
    summary: "summary",
    totalRows: "totalRows",
    totalPages: "totalPages",
    totalIncidents: "totalIncidents",
    totals: "totals",
    user: "user",
    volunteer: "volunteer",
    emergencies: "emergencies",
    totalResponse: "totalResponse"
  },
  component: {
    dashboard: "dashboard",
    dashboardSummary: "dashboardSummary",
    emergencyData: "emergencyData",
    volunteer: "volunteer"
  },
  place: {
    ambulance: "ambulance",
    bpbd: "bpbd",
    firefighter: "firefighter",
    hospital: "hospital",
    police: "police",
    volunteer: "volunteer"
  },
  title: {
    ambulance: "Ambulan",
    relawan: "Relawan",
    bpbd: "BPBD",
    hospital: "RS & Faskes",
    police: "Kepolisian",
    firefighter: "Damkar"
  }
};

export const heroTypes: HeroTypes = {
  ambulance: "ambulance",
  bpbd: "bpbd",
  firefighter: "firefighter",
  hospital: "hospital",
  police: "police",
  user: "user",
  volunteer: "volunteer"
};
