import axios from "axios";
import { c } from "../constant";
import { observable, action, set, toJS } from "mobx";
import { getUsersByHeroCategory } from "../api/users";
import {
  getEmergencies as fetchEmergencies,
  getActiveEmergencies as fetchActiveEmergencies,
  fetchCategories,
  storeCategory,
  doEmergencyActionAdmin
} from "../api/emergency";

interface IEmergencyData {
  isError: boolean;
  isLoading: boolean;
  isConfirmCancel: boolean;
  isConfirmFinish: boolean;
  selectedId: string;
  selectedName: string;
  currentPage: number;
  totalPages: number;
  totalRows: 0;
  rows: Array<Object>;
  pagination: {
    size: number;
    page: number;
  };
}

interface IEmergencyConfirm {
  categoryId: string;
  victimsTreated: number;
  victimsDied: number;
}

interface IEmergencyDashboard {
  isError: boolean;
  isLoading: boolean;
  emergencies: Array<any>;
  pagination: {
    size: number;
    page: number;
  };
}

export interface IEmergencyStore {
  isError: boolean;
  isLoading: boolean;
  isCategoryLoading: boolean;
  emergencyData: IEmergencyData;
  emergencyConfirm: IEmergencyConfirm;
  dashboard: IEmergencyDashboard;
  categories: Array<any>;
  selectedCategory: any;
  getEmergencies(token: any, page?: number, type?: string): void;
  getActiveEmergencies(token: any, page?: number): void;
  updateEmergencyData(key: string, value: any): void;
  updateEmergencyConfirm(key: string, value: any): void;
  createCategory(names: string): void;
  getCategories(token: any): void;
  updateSelectedCategory(value: any): void;
  setEmergencyAction(id: any, action: "cancel" | "finish"): Promise<any>;
}

export class EmergencyStore {
  @observable
  isLoading = false;
  @observable
  isCategoryLoading = false;

  @observable
  isError = false;

  @observable
  categories: Array<any> = [];

  @observable
  emergencyData = {
    isError: false,
    isLoading: false,
    isConfirmCancel: false,
    isConfirmFinish: false,
    selectedId: "",
    selectedName: "",
    currentPage: 1,
    totalPages: 0,
    totalRows: 0,
    rows: [],
    pagination: {
      size: 8,
      page: 1
    }
  };

  @observable
  selectedCategory: any = null;

  @observable
  emergencyConfirm = {
    categoryId: "",
    victimsTreated: 0,
    victimsDied: 0
  };

  @observable
  dashboard = {
    isError: false,
    isLoading: false,
    emergencies: [],
    pagination: {
      size: 5000,
      page: 1
    }
  };

  @action
  updateSelectedCategory = (value: any) => {
    this.selectedCategory = value;
  };

  @action
  updateDashboard = (key: string, value: any) => {
    set(this.dashboard, key, value);
  };

  @action
  updateEmergencyData = (key: string, value: any) => {
    set(this.emergencyData, key, value);
  };

  @action
  updateEmergencyConfirm = (key: string, value: any) => {
    set(this.emergencyConfirm, key, value);
  };

  @action
  resetEmergencyConfirm() {
    this.emergencyConfirm = {
      categoryId: "",
      victimsTreated: 0,
      victimsDied: 0
    };
  }

  @action
  setCategories = (categories: any) => {
    this.categories = categories;
  };

  @action
  createCategory = (name: string) => {
    this.isCategoryLoading = true;
    storeCategory(name).then(({ data }) => {
      const newCategory = {
        value: data._id,
        label: data.name
      };
      this.categories.push(newCategory);
      this.selectedCategory = newCategory;
      this.updateEmergencyConfirm("categoryId", newCategory.value);
      this.isCategoryLoading = false;
    });
  };

  @action
  getCategories = (token: any) => {
    this.isCategoryLoading = true;
    fetchCategories(token).then(({ data }) => {
      const categories = data.rows.map(value => {
        return { value: value._id, label: value.name };
      });
      this.setCategories(categories);
      this.isCategoryLoading = false;
    });
  };

  @action
  setEmergencyAction = (id: any, action: "cancel" | "finish") => {
    this.isLoading = true;
    if (action === "finish") {
      return doEmergencyActionAdmin(
        id,
        action,
        toJS(this.emergencyConfirm)
      ).then(rsp => {
        this.isLoading = false;
        this.resetEmergencyConfirm();
      });
    }
    return doEmergencyActionAdmin(id, action).then(rsp => {
      this.isLoading = false;
      this.resetEmergencyConfirm();
    });
  };

  @action
  getEmergencies = (token: any, page?: number, type?: string) => {
    let update = this.updateDashboard;
    let pagination = this.dashboard.pagination;

    if (type === c.component.emergencyData) {
      if (page) {
        this.emergencyData.pagination.page = page;
      }
      update = this.updateEmergencyData;
      pagination = this.emergencyData.pagination;
    }

    update(c.key.isLoading, true);
    return fetchEmergencies(token, pagination)
      .then((data: any) => {
        if (type === c.component.emergencyData) {
          update(c.key.currentPage, data.current_page);
          update(c.key.totalPages, data.last_page);
          update(c.key.rows, data.rows);
          update(c.key.totalRows, data.total);
        } else {
          this.dashboard.pagination.page++;
          update(c.key.emergencies, data.rows);
        }
      })
      .catch(() => update(c.key.isError, true))
      .then(() => update(c.key.isLoading, false));
  };

  @action
  getActiveEmergencies = (token: any, page?: number) => {
    const { updateEmergencyData: update, emergencyData } = this;
    if (page) {
      emergencyData.pagination.page = page;
    }
    update(c.key.isLoading, true);
    return fetchActiveEmergencies(token, emergencyData.pagination)
      .then((data: any) => {
        update(c.key.currentPage, data.current_page);
        update(c.key.totalPages, data.last_page);
        update(c.key.rows, data.rows);
        update(c.key.totalRows, data.total);
        emergencyData.pagination.page = data.current_page;
      })
      .catch(() => update(c.key.isError, true))
      .then(() => update(c.key.isLoading, false));
  };
}

export default new EmergencyStore();
