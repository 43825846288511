import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler } from "reactstrap";

import Logo from "../img/brand/logo.png";

interface ISideNavState {
  isOpen: boolean;
}

class SideNav extends React.Component<any, ISideNavState> {
  constructor(props: any) {
    super(props);

    this._handleToggle = this._handleToggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  _handleToggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    return (
      <Navbar
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light
        bg-white"
      >
        <div className="container-fluid">
          <NavbarToggler onClick={this._handleToggle}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>

          <Collapse
            className="collapse navbar-collapse"
            id="sidenav-collapse-main"
            isOpen={this.state.isOpen}
            navbar
          >
            <div className="navbar-collapse-header d-md-none">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <a href="./index.html">
                    {/* <img src={Logo} /> */}
                    Virtual Poison Center
                  </a>
                </div>
                <div className="col-6 collapse-close">
                  <NavbarToggler onClick={this._handleToggle}>
                    <span />
                    <span />
                  </NavbarToggler>
                </div>
              </div>
            </div>

            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="ni ni-tv-2 text-primary" /> Dashboard
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/volunteer"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="ni ni-user-run text-yellow" /> Relawan
                </NavLink> */}
              {/* </li> */}
              <li className="nav-item">
                <NavLink
                  to="/hospital"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="fa fa-hospital-alt text-cyan" /> Rumah Sakit &
                  Faskes
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/ambulance"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="fa fa-ambulance text-danger" /> Ambulan
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink
                  to="/community"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="fa fa-users text-dark" /> Komunitas
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  to="/user"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="ni ni-single-02 text-info" /> Pengguna
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/emergency"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="ni ni-support-16 text-warning" /> Darurat
                  Bantuan
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </div>
      </Navbar>
    );
  }
}

export { SideNav };
