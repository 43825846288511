// dummy
import { TOKEN_KEYWORD, BASE_URL, axios, stringify } from "./config";

class Auth {
  useLocalStorage: boolean;
  token: string | null;
  userData: any;

  constructor() {
    this.useLocalStorage = typeof localStorage !== "undefined";
    this.token = null;

    if (this.useLocalStorage) {
      this.token = localStorage.getItem(TOKEN_KEYWORD);

      this.userData = JSON.parse(localStorage.getItem("krekiuserData") || "{}");
      // TODO: verify token
      // if (token is NOT verified) {
      // this.token = null;
      // }
    }
  }

  isLoggedIn() {
    return !!this.token;
  }

  getToken() {
    return this.token;
  }

  getUserData() {
    return this.userData;
  }

  setUserData(userData: any) {
    this.userData = userData;

    if (this.useLocalStorage) {
      localStorage.setItem("krekiuserData", JSON.stringify(userData));
    }
  }

  setToken(token: string) {
    this.token = token;

    if (this.useLocalStorage) {
      localStorage.setItem(TOKEN_KEYWORD, token);
    }
  }

  removeToken() {
    this.token = null;

    if (this.useLocalStorage) {
      localStorage.removeItem(TOKEN_KEYWORD);
    }
  }

  async login(email: string, password: string) {
    try {
      const { data } = await axios.post("/users/u/login", {
        email: email,
        password: password,
      });
      this.setUserData(data.user);
      this.setToken(data.token);
      return true;
    } catch (error) {
      if (error.response.status == 401) {
        return false;
      }
      throw error;
    }
  }

  logout() {
    // TODO: use logout endpoint
    this.removeToken();
  }
}

export const auth = new Auth();
