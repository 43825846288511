import * as React from "react";
import { TopMenu } from "./TopMenu";

interface IPropsTopNav {
  title: string;
  username: string;
}

function TopNav({ title, username }: IPropsTopNav) {
  return (
    <nav
      className="navbar navbar-top navbar-expand-md navbar-dark"
      id="navbar-main"
    >
      <div className="container-fluid">
        <a
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          href="/dashboard"
        >
          {title}
        </a>

        <TopMenu username="Dashboard" />
      </div>
    </nav>
  );
}

export { TopNav };
