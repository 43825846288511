import { HEROES_ID, HeroType, axios } from "./config";
import { auth } from "./auth";

async function getNearby(
  cancelToken: any,
  reference: { lat: number; lng: number; radius?: number },
  pagination: { size: number; page: number },
  hero?: HeroType
) {
  const heroId = hero ? HEROES_ID[hero] : "";
  try {
    const { data } = await axios.get(
      `heroes/nearby?` +
        `lat=${reference.lat}&` +
        `lng=${reference.lng}&` +
        `radius=${reference.radius}&` +
        `size=${pagination.size}&` +
        `page=${pagination.page}&` +
        `category=${heroId}`,
      {
        cancelToken: cancelToken,
        headers: {
          "X-Access-Token": auth.getToken()
        }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

function getHeroCategories(
  cancelToken: any,
  pagination: { size: number; page: number }
) {
  return axios.get(
    `heroes/category?size=${pagination.size}&page=${pagination.page}`,
    {
      cancelToken: cancelToken,
      headers: {
        "X-Access-Token": auth.getToken()
      }
    }
  );
}

export { getNearby, getHeroCategories };
