import React from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";

import { createUser, getUser, updateUser } from "../api/users";

interface AmbulanceModalFormProps {
  isOpen?: boolean;
  updateId: string;
  title: string;
  onSuccess: () => any;
  onClosed: () => any;
}

interface AmbulanceModalFormState {
  fields: any; //UserFields;
  isError: boolean;
  isLoading: boolean;
}

class AmbulanceModalForm extends React.Component<
  AmbulanceModalFormProps,
  AmbulanceModalFormState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      fields: {
        address: "",
        email: "",
        lat: 0,
        lon: 0,
        fullName: "",
        password: "",
        phoneNumber: "",
        role: "volunteer"
      },
      isError: false,
      isLoading: false
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }));
  }

  async _handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { fields } = this.state;
    const { onSuccess, updateId } = this.props;
    this.setState({ isLoading: true });

    if (
      !fields.address &&
      !fields.lat &&
      !fields.lon &&
      !fields.fullName &&
      !fields.password &&
      !fields.phoneNumber
    ) {
      return false;
    }

    try {
      if (updateId) {
        await updateUser(updateId, fields);
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil diedit" />
        );
      } else {
        await createUser(fields, "ambulance");
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil ditambahkan" />
        );
        this.setState({
          fields: {
            address: "",
            email: "",
            lat: 0,
            lon: 0,
            fullName: "",
            password: "",
            phoneNumber: "",
            role: "volunteer"
          }
        });
      }

      if (typeof onSuccess === "function") {
        onSuccess();
        this.props.onClosed();
      }
    } catch (error) {
      this.setState({ isError: true });
    }

    this.setState({
      isLoading: false
    });
  }

  _handleToggle() {
    if (!this.state.isLoading) {
      this.props.onClosed();
    }
  }

  componentDidUpdate(prevProps: AmbulanceModalFormProps) {
    // if modal is dismissed
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        fields: {
          address: "",
          email: "",
          lat: 0,
          lon: 0,
          fullName: "",
          password: "",
          phoneNumber: "",
          role: "volunteer"
        },
        isError: false
      });
    }

    // if modal is on EDIT mode
    if (this.props.isOpen && !prevProps.updateId && this.props.updateId) {
      this.setState({ isLoading: true });

      getUser(this.props.updateId)
        .then(response => {
          const data: any = response.data;
          this.setState({
            fields: {
              address: data["address"],
              email: data["email"],
              lat: data["location"]["coordinates"][1],
              lon: data["location"]["coordinates"][0],
              fullName: data["full_name"],
              password: data["password"],
              phoneNumber: data["phone_number"],
              role: "volunteer"
            }
          });
        })
        .catch(() => this.setState({ isError: true }))
        .then(() => this.setState({ isLoading: false }));
    }
  }
  render() {
    const { isOpen, title, updateId } = this.props;
    const { fields, isError, isLoading } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>
          {title} : {updateId ? "EDIT" : "TAMBAH"}
        </ModalHeader>
        <ModalBody>
          {isError && (
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          )}

          <Form onSubmit={this._handleSubmit}>
            <FormGroup>
              <Label for="fullName">Nama</Label>
              <Input
                disabled={isLoading}
                id="fullName"
                name="fullName"
                placeholder="Masukkan nama"
                required={true}
                type="text"
                value={fields.fullName}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Alamat</Label>
              <Input
                disabled={isLoading}
                id="address"
                name="address"
                placeholder="Masukkan alamat"
                required={true}
                type="text"
                value={fields.address}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Nomor telepon</Label>
              <Input
                disabled={isLoading}
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Masukkan nomor telepon"
                required={true}
                type="text"
                value={fields.phoneNumber}
                onChange={this._handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lat">Latitude (LAT)</Label>
              <Input
                disabled={isLoading}
                id="lat"
                name="lat"
                placeholder="Masukkan latitude"
                required={true}
                type="number"
                value={fields.lat}
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Latitude harus bernilai desimal. Contoh: -8.182
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="lon">Longitude (LNG)</Label>
              <Input
                disabled={isLoading}
                id="lon"
                name="lon"
                placeholder="Masukkan longitude"
                required={true}
                type="number"
                value={fields.lon}
                onChange={this._handleChange}
              />
              <FormText color="muted">
                Longitude harus bernilai desimal. Contoh: 111.117
              </FormText>
            </FormGroup>

            <Button type="submit" color="primary" disabled={isLoading}>
              Submit
            </Button>

            <Button
              type="button"
              color="default"
              disabled={isLoading}
              onClick={this._handleToggle}
            >
              Batal
            </Button>

            {isLoading && (
              <LoadingIcon
                stroke="#32325d"
                style={{ width: "1em", height: "1em" }}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export { AmbulanceModalForm };
