import * as React from "react";
import { Redirect } from "react-router-dom";

import { auth } from "../api/auth";

class Logout extends React.Component<any> {
  constructor(props: any) {
    super(props);

    auth.logout();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export { Logout };
