import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { AuthenticatedUserContext } from "../contexts";

import IconSketch from "../img/theme/sketch.jpg";

interface ITopMenuState {
  isOpen: boolean;
}

interface ITopMenuProps {
  username: string;
}

class TopMenu extends React.Component<ITopMenuProps, ITopMenuState> {
  constructor(props: ITopMenuProps) {
    super(props);

    this._handleToggle = this._handleToggle.bind(this);

    this.state = {
      isOpen: false
    };
  }

  _handleToggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    return (
      <div className="navbar-nav align-items-center d-none d-md-flex">
        <Dropdown
          className="nav-item"
          toggle={this._handleToggle}
          isOpen={this.state.isOpen}
          tag="div"
        >
          <DropdownToggle className="nav-link pr-0" tag="a">
            <AuthenticatedUserContext.Consumer>
              {user => (
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="Foto" src={user.photo} />
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold">
                      {user.name}
                    </span>
                  </div>
                </div>
              )}
            </AuthenticatedUserContext.Consumer>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow misc--dropdown-menu"
            right
          >
            <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Halo!</h6>
            </div>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="ni ni-user-run" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export { TopMenu };
