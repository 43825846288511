import React from "react";
import { observer, inject } from "mobx-react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Table,
  ModalFooter
} from "reactstrap";
import { toast } from "react-toastify";
import { ToastMessage } from "./ToastMessage";
import { axios } from "../api/config";
import { IUserStore } from "../stores/userStore";

interface VolunteerModalFormProps {
  isOpen?: boolean;
  userId: string;
  onClosed: () => any;
}

interface VolunteerModalFormState {
  fields: any;
  isLoading: boolean;
  isError: boolean;
}

interface InjectedProps extends VolunteerModalFormProps {
  userStore: IUserStore;
}

@inject("userStore")
@observer
class VolunteerModalDetail extends React.Component<
  VolunteerModalFormProps,
  VolunteerModalFormState
> {
  _source = axios.CancelToken.source();
  constructor(props: any) {
    super(props);

    this._loadUser = this._loadUser.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._changeStatus = this._changeStatus.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  async _changeStatus(e: React.FormEvent, status: "accepted" | "rejected") {
    e.preventDefault();
    const { updateData, changeStatus } = this.injected.userStore;
    try {
      await changeStatus(this._source.token, status);
      let msg = status === "accepted" ? " diterima" : "ditolak";
      toast.success(
        <ToastMessage icon="fa-check" text={`Member berhasil ${msg}`} />
      );
      this.props.onClosed();
    } catch (err) {
      updateData("isError", true);
    }
  }

  _handleToggle() {
    this.props.onClosed();
  }

  async _loadUser() {
    const { getUser, updateData } = this.injected.userStore;

    try {
      getUser(this._source.token, this.props.userId || "");
    } catch (err) {
      updateData("isError", true);
    }
  }

  componentDidUpdate(prevProps: VolunteerModalFormProps) {
    if (this.props.isOpen && !prevProps.userId && this.props.userId) {
      this._loadUser();
    }
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { isOpen } = this.props;
    const { isFormVolunteer, form_volunteer } = this.injected.userStore.data;
    const {
      address,
      address_domicile,
      email,
      full_name,
      identity_number,
      phone_number,
      photo_certificate,
      status
    } = form_volunteer;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this._handleToggle}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this._handleToggle}>Data User</ModalHeader>
        <ModalBody>
          {isFormVolunteer ? (
            <Table responsive>
              <tbody>
                <tr>
                  <th>Nama Lengkap</th>
                  <td>{full_name}</td>
                </tr>
                <tr>
                  <th>No KTP</th>
                  <td>{identity_number}</td>
                </tr>
                <tr>
                  <th>Alamat (sesuai KTP)</th>
                  <td>{address}</td>
                </tr>
                <tr>
                  <th>Alamat Domisili</th>
                  <td>{address_domicile}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th>No Telp</th>
                  <td>{phone_number}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{status}</td>
                </tr>
                <tr>
                  <th>Sertifikat</th>
                  <td>
                    <img
                      className="img-thumbnail"
                      src={photo_certificate}
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            "Pengguna belum mengisi form volunteer"
          )}
        </ModalBody>
        <ModalFooter>
          {isFormVolunteer && status === "waiting" ? (
            <React.Fragment>
              <Button
                color="primary"
                onClick={e => this._changeStatus(e, "rejected")}
              >
                Tolak
              </Button>

              <Button
                color="danger"
                onClick={e => this._changeStatus(e, "accepted")}
              >
                Terima
              </Button>
            </React.Fragment>
          ) : (
            <Button color="primary" onClick={this._handleToggle}>
              Tutup
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export { VolunteerModalDetail };
