import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { ImageModal } from "./ImageModal";
import { UserModalForm } from "./UserModalForm";
import { VolunteerModalDetail } from "./VolunteerModalDetail";
import { DeleteConfirm } from "./DeleteConfirm";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { IUserStore } from "../stores/userStore";

interface UserDataTableProps {
  /** Indicates loading from parent component */
  isLoading: boolean;
  /** Pagination: total rows per page */
  pageSize: number;
  type: string;
}

interface InjectedProps extends UserDataTableProps {
  userStore: IUserStore;
}

interface AppState {
  isImageModal: boolean;
  selectedSrc: string;
}

@inject("userStore")
@observer
class UserDataTable extends React.Component<UserDataTableProps, AppState> {
  _source = axios.CancelToken.source();

  constructor(props: UserDataTableProps) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._search = this._search.bind(this);
  }

  state = {
    isImageModal: false,
    selectedSrc: ""
  };

  get injected() {
    return this.props as InjectedProps;
  }

  public static defaultProps = {
    pageSize: 8,
    type: "user"
  };

  _openImage = (src: string) => {
    this.setState({ isImageModal: true, selectedSrc: src });
  };

  _loadData(page?: number) {
    const { getUsers, listByFormVolunteer } = this.injected.userStore;
    if (this.props.type === "waiting") {
      listByFormVolunteer(this._source.token, page);
      return;
    }
    getUsers(this._source.token, page);
  }

  onClose(type?: string) {
    const { updateData } = this.injected.userStore;

    updateData("selectedId", "");
    if (type === "delete") {
      updateData("isDeleteConfirm", false);
      return;
    }
    updateData("isUserModalForm", false);
  }

  onAdd() {
    const { updateData } = this.injected.userStore;
    updateData("isUserModalForm", true);
  }

  onConfirm(id: number, type?: string) {
    const { updateData } = this.injected.userStore;
    updateData("selectedId", id);
    if (type === "delete") {
      updateData("isDeleteConfirm", true);
      return;
    }
    updateData("isUserModalForm", true);
  }

  showDetail(id: string) {
    const { updateData } = this.injected.userStore;
    updateData("selectedId", id);
    updateData("showDetail", true);
  }

  hideDetail() {
    const { updateData } = this.injected.userStore;
    updateData("showDetail", false);
    updateData("selectedId", "");
  }

  _search(value: string) {
    const { search, searchByFormVolunteer } = this.injected.userStore;
    if (value === "") {
      this._loadData();
    } else {
      if (this.props.type === "waiting") {
        searchByFormVolunteer(this._source.token, value);
        return;
      }
      search(this._source.token, value);
    }
  }

  componentDidMount() {
    this._loadData();
  }

  componentDidUpdate(prevProps: UserDataTableProps) {
    if (prevProps.type !== this.props.type) {
      this._loadData(1);
    }
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { pageSize, type } = this.props;
    const { waitingList } = this.injected.userStore;
    const { selectedSrc, isImageModal } = this.state;
    const {
      isLoading,
      isDeleteConfirm,
      isUserModalForm,
      showDetail,
      selectedId,
      currentPage: dataCurrentPage,
      totalPages: dataTotalPage,
      totalRows,
      rows
    } = this.injected.userStore.data;
    let data: Array<any> = [];
    let currentPage = dataCurrentPage;
    let totalPages = dataTotalPage;

    if (type === "user") {
      data = rows;
    } else if (type === "waiting") {
      data = waitingList.rows;
      currentPage = waitingList.currentPage;
      totalPages = waitingList.totalPage;
    }

    return (
      <React.Fragment>
        {isImageModal && (
          <ImageModal
            src={selectedSrc}
            onClosed={() =>
              this.setState({ isImageModal: false, selectedSrc: "" })
            }
          />
        )}
        <VolunteerModalDetail
          isOpen={showDetail}
          userId={selectedId}
          onClosed={() => this.hideDetail()}
        />
        <UserModalForm
          isOpen={isUserModalForm}
          updateId={selectedId}
          title="Form Relawan"
          onSuccess={() => this._loadData(currentPage)}
          onClosed={() => this.onClose()}
        />
        <DeleteConfirm
          isOpen={isDeleteConfirm}
          deleteId={selectedId}
          deleteType="user"
          title="Apakah Anda yakin akan menghapus item?"
          onSuccess={() =>
            this._loadData(
              totalRows % pageSize == 1 ? currentPage - 1 : currentPage
            )
          }
          onClosed={() => {
            this.onClose("delete");
          }}
        />

        <DataTable2
          headings={["NAMA", "ROLE", "NOMOR TELEPON", ""]}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}

          onSearch={this._search}
          title={`List ${type === "user" ? "Pengguna" : "Menunggu Konfirmasi"}`}
          toolbar={
            <Button color="primary" size="sm" onClick={() => this.onAdd()}>
              Tambah
            </Button>
          }
          onPaginate={this._loadData}
          renderRows={() =>
            data.map((user: any) => (
              <DataTableRow
                key={user._id}
                name={user.full_name}
                phone={user.phone_number}
                photo={user.photo}
                role={user.role_id}
                openImage={this._openImage}
                status={user.form_volunteer ? user.form_volunteer.status : ""}
                onShowDeleteConfirm={() => {
                  this.onConfirm(user._id, "delete");
                }}
                onShowUserModalForm={() => {
                  this.onConfirm(user._id);
                }}
                onShowUserModalDetail={() => {
                  this.showDetail(user._id);
                }}
                isFromVolunteer={typeof user.form_volunteer !== "undefined"}
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  name,
  phone,
  photo,
  role,
  status,
  openImage,
  onShowDeleteConfirm,
  onShowUserModalForm,
  onShowUserModalDetail,
  isFromVolunteer
}: any) {
  const DEFAULT_IMAGE = "http://i.imgur.com/lL3LtFD.jpg"

  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <span
            onClick={() => openImage(photo)}
            className="avatar rounded-circle mr-3 cursor-pointer"
          >
            <img
              src={
                photo
                  ? photo
                  : "https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
              }

              onError={(e) => { DEFAULT_IMAGE }}
            />
          </span>
          <div className="media-body">
            <span className="mb-0 text-sm">{name}</span>
          </div>
        </div>
      </th>
      <td>{role ? role.group : "-"}</td>
      <td>{phone}</td>
      <td>
        {role.group != "superadmin" && <Button color="warning" size="sm" onClick={onShowUserModalForm}>
          Edit
        </Button>}
        {role.group != "superadmin" && <Button color="danger" size="sm" onClick={onShowDeleteConfirm}>
          Hapus
        </Button>}
        {isFromVolunteer && status === "waiting" && (
          <Button color="primary" size="sm" onClick={onShowUserModalDetail}>
            Periksa
          </Button>
        )}
        {isFromVolunteer && status === "accepted" && (
          <Button color="primary" size="sm" onClick={onShowUserModalDetail}>
            Detail
          </Button>
        )}
      </td>
    </tr>
  );
}

export { UserDataTable };
