import { heroTypes } from "../constant";
import { observable, action } from "mobx";
import { HEROES_ID } from "../api/config";
import API from "../api/API";

export interface IAmbulanceStore {
  isError: boolean;
  isLoading: boolean;
  data: Array<any>;
  pagination: {
    currentPage: number;
    totalPage: number;
  };
  setData(values: Array<any>): void;
  addData(value: any): void;
  search(token: any, value: string): Promise<any>;
  list(token: any, page?: number, size?: number): Promise<any>;
}

const AmbulanceHeroId = HEROES_ID[heroTypes.ambulance];

export class AmbulanceStore implements IAmbulanceStore {
  @observable
  isLoading = false;

  @observable
  isError = false;

  @observable
  data: Array<any> = [];

  @observable
  pagination = {
    currentPage: 1,
    totalPage: 0
  };

  @action
  setData = (values: Array<any>) => {
    this.data = values;
  };

  @action
  addData = (value: any) => {
    this.data.push(value);
  };

  @action
  list = (token: any, page: number = 1, size: number = 10) => {
    const { setData } = this;
    this.isLoading = true;
    this.pagination.currentPage = page;

    return API.User.getUsersByHeroCategory(token, AmbulanceHeroId, size, page)
      .then((data: any) => {
        setData(data.rows);
        this.isLoading = false;
        this.pagination.totalPage = data.last_page;
      })
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false));
  };

  @action
  search = (token: any, value: string) => {
    const { setData } = this;
    this.isLoading = true;
    return API.User.searchByHeroCategory(token, AmbulanceHeroId, value)
      .then((data: any) => {
        setData(data.rows);
      })
      .catch(() => (this.isError = true))
      .finally(() => (this.isLoading = false));
  };
}

export default new AmbulanceStore();
