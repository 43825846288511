import * as React from "react";
import { Map, MarkerAttributes } from "../components/Map";
import { HospitalDataTable } from "../components/HospitalDataTable";
import { PlaceSummary } from "../components/PlaceSummary";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { IPlaceStore } from "../stores/placeStore";
import { c, heroTypes } from "../constant";

import IconHospitalMarker from "../img/icons/marker/loc_hospital.svg";

interface Props {}

interface InjectedProps extends Props {
  placeStore: IPlaceStore;
}

interface HospitalState {
  isError: boolean;
  isLoading: boolean;
  places: Array<any>;
}

@inject("placeStore")
@observer
class Hospital extends React.Component<Props, HospitalState> {
  _source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._search = this._search.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData(page?: number) {
    const { getPlaces } = this.injected.placeStore;
    getPlaces(this._source.token, heroTypes.hospital, page);
  }

  _search(value: string) {
    this.injected.placeStore.search(
      this._source.token,
      heroTypes.hospital,
      value
    );
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const {
      isError,
      isLoading,
      places,
      currentPage,
      totalPage
    } = this.injected.placeStore[heroTypes.hospital];
    const markers: Array<MarkerAttributes> = places.map((place: any) => ({
      icon: IconHospitalMarker,
      lat: place.location.coordinates[1],
      lng: place.location.coordinates[0],
      infoWindow: `<h4>${place.data.name}</h4> ${place.data.phone || "-"}`
    }));

    return (
      <React.Fragment>
        <div className="header bg-gradient-cyan pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body">
              <PlaceSummary
                placeTitle={c.title.hospital}
                placeType={heroTypes.hospital}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}

          <div className="row">
            <div className="col">
              <Map markers={markers} />
            </div>
          </div>
          <hr className="my-3" />
          <div className="row">
            <div className="col">
              <HospitalDataTable
                isLoading={isLoading}
                rows={places}
                loadData={this._loadData}
                onSearch={this._search}
                currentPage={currentPage}
                totalPage={totalPage}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Hospital };
