import * as React from "react";
import { Redirect } from "react-router";
import { LoadingIcon } from "../components/LoadingIcon";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label
} from "reactstrap";

import { auth } from "../api/auth";
import { close, socket } from "../api/emergency-ws";

class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this._handleLogin = this._handleLogin.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this.state = {
      email: "",
      isFailed: false,
      isError: false,
      isLoading: false,
      password: "",
      shouldRedirect: auth.isLoggedIn()
    };

    // Close websocket if any
    if (socket) {
      close();
    }
  }

  _redirectPath() {
    const locationState = this.props.location.state;
    const pathname =
      locationState && locationState.from && locationState.from.pathname;
    return pathname || "/dashboard";
  }

  _handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  }

  _handleLogin(e: React.FormEvent) {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    // TODO: handle login error with try catch
    // try {
    //   const success = await auth.login(email, password);

    //   this.setState({ isLoading: false });
    //   if (success) {
    //     this.setState({ shouldRedirect: true });
    //   } else {
    //     this.setState({ isFailed: true });
    //   }
    // } catch (error) {
    //   this.setState({ isError: true, isLoading: false });
    // }

    auth
      .login(email, password)
      .then(status => {
        if (status) {
          this.setState({ shouldRedirect: true });
        } else {
          this.setState({ isFailed: true, isLoading: false });
        }
      })
      .catch(() => this.setState({ isError: true, isLoading: false }));
  }

  render() {
    const { isFailed, isError, isLoading, shouldRedirect } = this.state;

    if (shouldRedirect) {
      return <Redirect to="dashboard" />;
    }

    return (
      <div className="main-content bg-gradient-success misc--bg-default">
        <div className="header bg-gradient-success py-7 py-lg-8">
          <div className="container">
            <div className="header-body text-center mb-7">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6">
                  <h1 className="text-white">Virtual Poison Center</h1>
                  <p className="text-lead text-light">
                    Sumber Terpercaya Anda untuk Panduan Keamanan dan Penanganan Racun Secara Cepat
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x="0"
              y="0"
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>

        <div className="container mt--8 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              {isError && (
                <Alert color="danger">
                  <strong>Terjadi kesalahan!</strong> Tidak bisa login
                </Alert>
              )}
              {isFailed && (
                <Alert color="warning">
                  <strong>Login gagal!</strong> Email dan password Anda tidak
                  valid.
                </Alert>
              )}
              <div className="card bg-secondary shadow border-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Sign in with credentials</small>
                  </div>
                  <Form onSubmit={this._handleLogin}>
                    <FormGroup>
                      <Label htmlFor="email" hidden>
                        Email
                      </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="ni ni-email-83" />
                          </span>
                        </InputGroupAddon>
                        <Input
                          disabled={isLoading}
                          id="email"
                          name="email"
                          placeholder="Email"
                          required={true}
                          type="email"
                          onChange={this._handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="password" hidden>
                        Password
                      </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="ni ni-lock-circle-open" />
                          </span>
                        </InputGroupAddon>
                        <Input
                          disabled={isLoading}
                          id="password"
                          name="password"
                          placeholder="Password"
                          required={true}
                          type="password"
                          onChange={this._handleChange}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                      >
                        <span>Sign in </span>
                        {isLoading && (
                          <LoadingIcon
                            style={{ width: "1em", height: "1em" }}
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Login };
