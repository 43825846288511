import { c } from "../constant";
import { observable, action, toJS } from "mobx";
import API from "../api/API";

interface Community {
  _id: string;
  name: string;
  photo: string;
  description: string;
  members: any[];
}

export interface ICommunityStore {
  isLoading: boolean;
  isError: boolean;
  data: {
    isDeleteConfirm: boolean;
    isError: boolean;
    isLoading: boolean;
    isModalForm: boolean;
    showDetail: boolean;
    currentPage: number;
    totalPages: number;
    totalRows: number;
    rows: Array<any>;
    selectedId: string;
  };
  selectedCommunity: Community;
  selectedMember: any;
  isDelete: boolean;
  search: (token: any, name: string) => Promise<any>;
  setPhoto(file: any): void;
  selectMember(value: any): void;
  getCommunities(token: any, page?: number): void;
  getCommunity(token: any, id: string): void;
  create(token: any): void;
  update(token: any): void;
  deleteCommunity(id: string): void;
  removeMember(token: any): void;
  updateData(key: string, value: any): void;
  updateSelectedCommunity(key: string, value: any): void;
  toggleDelete(condition?: boolean): void;
  reset(): void;
}

export class CommunityStore {
  @observable
  isLoading = false;

  @observable
  isError = false;

  @observable
  isDelete = false;

  @observable
  selectedCommunity: Community = {
    _id: "",
    name: "",
    description: "",
    photo: "",
    members: []
  };

  @observable
  selectedMember: any = undefined;

  @observable
  photo = "";

  @action
  toggleDelete = () => {
    this.isDelete = !this.isDelete;
  };

  @action
  selectMember = (value: any) => {
    this.selectedMember = value;
  };

  @action
  reset = () => {
    this.selectedCommunity = {
      _id: "",
      name: "",
      description: "",
      photo: "",
      members: []
    };

    this.photo = "";
    this.isLoading = false;
    this.isError = false;
  };

  @observable
  data = {
    isDeleteConfirm: false,
    isError: false,
    isLoading: false,
    isModalForm: false,
    showDetail: false,
    currentPage: 1,
    totalPages: 1,
    totalRows: 0,
    rows: [],
    selectedId: ""
  };

  @action
  setPhoto(file) {
    this.photo = file;
  }

  @action
  updateData = (key: string, value: any) => {
    this.data[key] = value;
  };

  @action
  updateSelectedCommunity = (key: string, value: any) => {
    this.selectedCommunity[key] = value;
  };

  @action
  search = (token: any, name: string) => {
    const { updateData } = this;
    updateData(c.key.isLoading, true);

    return API.Community.search(token, name)
      .then((data: any) => {
        updateData(c.key.rows, data.rows);
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true));
  };

  @action
  getCommunities = (token: any, page: number = 1, size: number = 10) => {
    const { updateData } = this;
    updateData("currentPage", page);
    updateData("isLoading", true);

    return API.Community.get(token, page, size)
      .then((data: any) => {
        updateData("rows", data.rows);
        updateData("totalPages", data.last_page);
      })
      .catch(() => updateData("isError", true))
      .finally(() => updateData("isLoading", false));
  };

  @action
  getCommunity = (token: any, id: string) => {
    const { updateData } = this;
    updateData(c.key.isLoading, true);

    return API.Community.getByID(token, id)
      .then((data: any) => {
        this.selectedCommunity = data;
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true))
      .then(() => updateData(c.key.isLoading, false));
  };

  @action
  create = (token: any) => {
    const { updateData, photo, selectedCommunity } = this;

    let payload = new FormData();
    payload.append("photo", photo);
    payload.append("name", selectedCommunity.name);
    payload.append("description", selectedCommunity.description);

    updateData(c.key.isLoading, true);

    return API.Community.create(token, payload)
      .then((data: any) => {
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true))
      .then(() => updateData(c.key.isLoading, false));
  };

  @action
  update = (token: any) => {
    const { updateData, selectedCommunity } = this;

    const payload = {
      _id: selectedCommunity._id,
      name: selectedCommunity.name,
      description: selectedCommunity.description
    };

    updateData(c.key.isLoading, true);

    return API.Community.update(token, payload)
      .then((data: any) => {
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true))
      .then(() => updateData(c.key.isLoading, false));
  };

  @action
  deleteCommunity = (id: string) => {
    const { updateData } = this;
    updateData(c.key.isLoading, true);

    return API.Community.delete(id)
      .then((data: any) => {
        updateData(c.key.isLoading, false);
      })
      .catch(() => updateData(c.key.isError, true))
      .then(() => updateData(c.key.isLoading, false));
  };

  @action
  removeMember = (token: any) => {
    const { updateData, selectedMember, selectedCommunity } = this;
    updateData(c.key.isLoading, true);

    return API.Community.removeMember(
      token,
      selectedCommunity._id,
      selectedMember._id
    )
      .then((data: any) => {
        updateData(c.key.isLoading, false);
        this.isDelete = false;
      })
      .catch(() => updateData(c.key.isError, true))
      .then(() => updateData(c.key.isLoading, false));
  };
}

export default new CommunityStore();
