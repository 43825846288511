import * as React from "react";
import { Map, MarkerAttributes } from "../components/Map";
import { AmbulanceDataTable } from "../components/AmbulanceDataTable";
import { PlaceSummary } from "../components/PlaceSummary";

import axios from "axios";
import { observer, inject } from "mobx-react";
import { IAmbulanceStore } from "../stores/ambulanceStore";
import { c, heroTypes } from "../constant";

import IconHospitalMarker from "../img/icons/marker/loc_hospital.svg";

interface Props {}

interface InjectedProps extends Props {
  ambulanceStore: IAmbulanceStore;
}

interface AmbulanceState {
  isError: boolean;
  isLoading: boolean;
  places: Array<any>;
}

@inject("ambulanceStore")
@observer
class Ambulance extends React.Component<Props, AmbulanceState> {
  _source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._search = this._search.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData() {
    const { list } = this.injected.ambulanceStore;
    list(this._source.token);
  }

  _search(value: string) {
    this.injected.ambulanceStore.search(this._source.token, value);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const {
      isError,
      isLoading,
      data,
      pagination
    } = this.injected.ambulanceStore;
    const markers: Array<MarkerAttributes> = data.map((ambulance: any) => ({
      icon: IconHospitalMarker,
      lat: ambulance.location.coordinates[1],
      lng: ambulance.location.coordinates[0],
      infoWindow: `<h4>${ambulance.full_name}</h4> ${ambulance.phone_number ||
        "-"}`
    }));

    return (
      <React.Fragment>
        <div className="header bg-gradient-cyan pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body">
              <PlaceSummary
                placeTitle={c.title.ambulance}
                placeType={heroTypes.ambulance}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}

          <div className="row">
            <div className="col">
              <Map
                markers={markers}
                center={
                  data.length > 0
                    ? {
                        lat: data[0].location.coordinates[1],
                        lng: data[0].location.coordinates[0]
                      }
                    : undefined
                }
              />
            </div>
          </div>
          <hr className="my-3" />
          <div className="row">
            <div className="col">
              <AmbulanceDataTable
                isLoading={isLoading}
                rows={data}
                loadData={this._loadData}
                onSearch={this._search}
                currentPage={pagination.currentPage}
                totalPage={pagination.totalPage}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Ambulance };
