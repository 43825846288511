import React from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { toast } from "react-toastify";
import { LoadingIcon } from "./LoadingIcon";
import { ToastMessage } from "./ToastMessage";
import { observer, inject } from "mobx-react";

import { deletePlace } from "../api/places";
import { deleteUser } from "../api/users";
import { ICommunityStore } from "../stores/communityStore";

interface DeleteConfirmProps {
  isOpen: boolean;
  deleteId: string;
  deleteType: "user" | "place" | "community";
  title: string;
  onSuccess: () => any;
  onClosed: () => any;
}

interface DeleteConfirmState {
  isError: boolean;
  isLoading: boolean;
}

interface InjectedProps extends DeleteConfirmProps {
  communityStore: ICommunityStore;
}

@inject("communityStore")
@observer
class DeleteConfirm extends React.Component<
  DeleteConfirmProps,
  DeleteConfirmState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isError: false,
      isLoading: false
    };

    this._handleToggle = this._handleToggle.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  _handleDelete(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { deleteId, deleteType, onSuccess } = this.props;
    const { deleteCommunity } = this.injected.communityStore;
    let del;

    if (deleteType == "user") {
      del = deleteUser;
    } else if (deleteType == "place") {
      del = deletePlace;
    } else if (deleteType == "community") {
      del = deleteCommunity;
    } else {
      return;
    }

    del(deleteId)
      .then(() => {
        this.setState({ isError: false });
        toast.success(
          <ToastMessage icon="fa-check" text="Data berhasil dihapus" />
        );
        onSuccess();
        this.props.onClosed();
      })
      .catch(() => this.setState({ isError: true }))
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  _handleToggle() {
    if (!this.state.isLoading) {
      this.props.onClosed();
    }
  }

  componentDidUpdate(prevProps: DeleteConfirmProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isError: false });
    }
  }

  render() {
    const { isOpen, title } = this.props;
    const { isError, isLoading } = this.state;

    const ModalAction = (
      <ModalFooter>
        {isLoading && (
          <LoadingIcon
            stroke="black"
            style={{ width: "1.5em", height: "1.5em" }}
          />
        )}

        <Button
          color="primary"
          disabled={isError || isLoading}
          onClick={this._handleDelete}
        >
          Ya
        </Button>
        <Button
          color="default"
          onClick={this._handleToggle}
          disabled={isError || isLoading}
        >
          Tidak
        </Button>
      </ModalFooter>
    );

    return (
      <Modal isOpen={isOpen} toggle={this._handleToggle} backdrop="static">
        <ModalHeader toggle={this._handleToggle}>{title}</ModalHeader>

        {isError && (
          <ModalBody>
            <Alert color="danger">
              <strong>Terjadi kesalahan!</strong>
            </Alert>
          </ModalBody>
        )}

        {!isError && ModalAction}
      </Modal>
    );
  }
}

export { DeleteConfirm };
