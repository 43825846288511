import * as React from "react";
import { Button } from "reactstrap";
import { DataTable2 } from "./DataTable2";
import { ModalConfirm } from "./ModalConfirm";

import { HeroType } from "../api/config";
import { getNearby } from "../api/heroes";
import { assignHero } from "../api/emergency";
import axios from "axios";

interface NearbyDataTableProps {
  /** Emergency id, passed from parent component*/
  emergencyId: any;
  /** Reference point (latitude and longitude)  */
  location: { lat: number; lng: number };
  /** Title of the data table */
  title: string;
  /** Type of nearby heroes */
  heroType: HeroType;
  /** Pagination: total rows per page */
  pageSize?: number;
}

interface NearbyDataTableState {
  /** Error state */
  isError: boolean;
  /** Loading state */
  isLoading: boolean;
  /** Visibility of ModalConfirm for hero assignment */
  isConfirmAssign: boolean;
  /** Selected hero's id for ModalConfirm  */
  selectedId: string;
  /** Selected heroe's name for ModalConfirm action */
  selectedName: string;

  /** Pagination: current page number */
  currentPage: number;
  /** Pagination: total number of page */
  totalPages: number;
  /** Pagination: total number of row in all page */
  totalRows: 0;
  /** Pagination: Rows of current page */
  rows: Array<Object>;
}

class NearbyDataTable extends React.Component<
  NearbyDataTableProps,
  NearbyDataTableState
> {
  _source = axios.CancelToken.source();

  static defaultProps = {
    pageSize: 5
  };
  constructor(props: NearbyDataTableProps) {
    super(props);

    this.state = {
      isError: false,
      isLoading: false,
      isConfirmAssign: false,
      selectedId: "",
      selectedName: "",
      currentPage: 1,
      totalPages: 0,
      totalRows: 0,
      rows: []
    };
    this._loadData = this._loadData.bind(this);
  }

  async _loadData(page: number) {
    const { location, pageSize, heroType } = this.props;

    try {
      this.setState({ isLoading: true });
      const data = await getNearby(
        this._source.token,
        { lat: location.lat, lng: location.lng, radius: 100 },
        { size: pageSize!, page: page },
        heroType
      );
      console.log(data);
      this.setState({
        isLoading: false,
        currentPage: data.current_page,
        totalPages: data.last_page,
        rows: data.rows,
        totalRows: data.total
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({ isError: true, isLoading: false });
      }
    }
  }

  componentDidMount() {
    this._loadData(this.state.currentPage);
  }

  render() {
    const { emergencyId, title } = this.props;
    const {
      isConfirmAssign,
      isLoading,
      selectedId,
      selectedName,
      currentPage,
      totalPages,
      rows
    } = this.state;

    return (
      <React.Fragment>
        <ModalConfirm
          isOpen={isConfirmAssign}
          text={`Apakah Anda yakin untuk menugaskan ${selectedName}?`}
          successMessage={`${selectedName} berhasil ditugaskan`}
          onConfirmed={() => assignHero(emergencyId, selectedId)}
          onSuccess={() => this._loadData(currentPage)}
          onClosed={() => {
            this.setState({
              isConfirmAssign: false,
              selectedId: "",
              selectedName: ""
            });
          }}
        />

        <DataTable2
          headings={["NAMA", "STATUS", ""]}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          title={title}
          onPaginate={this._loadData}
          renderRows={() =>
            rows.map((hero: any) => (
              <DataTableRow
                key={hero._id}
                name={hero.full_name}
                status={hero.status}
                onAssignButtonClick={() =>
                  this.setState({
                    isConfirmAssign: true,
                    selectedId: hero._id,
                    selectedName: hero.full_name
                  })
                }
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({ name, status, onAssignButtonClick }: any) {
  return (
    <tr>
      <th>{name}</th>
      <td>{status}</td>
      <td>
        <Button color="primary" size="sm" onClick={onAssignButtonClick}>
          Tugaskan
        </Button>
      </td>
    </tr>
  );
}

export { NearbyDataTable };
