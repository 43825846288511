import * as React from "react";
import { Map, MarkerAttributes } from "../components/Map";
import { EmergencyDataTable2 } from "../components/EmergencyDataTable2";
import { DashboardSummary } from "../components/DashboardSummary";

import IconHelpMarker from "../img/icons/marker/loc_me.svg";
import { axios } from "../api/config";

import { observer, inject } from "mobx-react";
import { IEmergencyStore } from "../stores/emergencyStore";

interface Props { }

interface InjectedProps extends Props {
  emergencyStore: IEmergencyStore;
}
interface DashboardState {
  isError: boolean;
  isLoading: boolean;
  emergencies: Array<any>;
}

@inject("emergencyStore")
@observer
class Dashboard extends React.Component<Props, DashboardState> {
  constructor(props: any) {
    super(props);
  }

  _source = axios.CancelToken.source();

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    const { getActiveEmergencies } = this.injected.emergencyStore;
    getActiveEmergencies(this._source.token);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const {
      isError,
      isLoading,
      rows,
    } = this.injected.emergencyStore.emergencyData;
    const data = rows as any;
    const isCenter: boolean = rows.length === 1 ? true : false;
    const markers: Array<MarkerAttributes> = rows.map((emergency: any) => ({
      icon: IconHelpMarker,
      lat: emergency.id_users.location.coordinates[1],
      lng: emergency.id_users.location.coordinates[0],
      infoWindow: `<h4>${emergency.id_users.full_name}</h4> ${emergency.id_users
        .phone_number || "-"}`,
    }));

    return (
      <React.Fragment>
        <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body">
              <DashboardSummary />
            </div>
          </div>
        </div>

        <div className="container-fluid mt--7">
          {isError && (
            <div className="alert alert-warning" role="alert">
              <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data
            </div>
          )}

          <hr className="my-3" />

          <div className="row">
            <div className="col">
              <Map
                markers={markers}
                center={{
                  lat: -6.20925,
                  lng: 106.8403643,
                }}
              />
            </div>
          </div>

          <hr className="my-3" />

          <div className="row">
            <div className="col">
              <EmergencyDataTable2 isLoading={isLoading} status="active" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { Dashboard };
