import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Alert from "reactstrap/lib/Alert";

interface VolunteerModalFormProps {
  src: string;
  onClosed: () => any;
}

class ImageModal extends React.Component<VolunteerModalFormProps, {}> {
  constructor(props: any) {
    super(props);

    this._handleToggle = this._handleToggle.bind(this);
  }

  _handleToggle() {
    this.props.onClosed();
  }

  render() {
    const { src } = this.props;
    return (
      <Modal isOpen={true} toggle={this._handleToggle}>
        <ModalHeader toggle={this._handleToggle}>Lihat Gambar</ModalHeader>
        <ModalBody className="text-center">
          {typeof src === "undefined" && (
            <Alert color="warning">Belum ada foto</Alert>
          )}
          {typeof src !== "undefined" && (
            <img src={src} className="img-fluid w-100" alt="" />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export { ImageModal };
