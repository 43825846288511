import * as React from "react";
import { CardSummary } from "./CardSummary";
import { LoadingIcon } from "./LoadingIcon";

import { axios, HeroType } from "../api/config";

import { observer, inject } from "mobx-react";
import { IPlaceStore } from "../stores/placeStore";

interface PlaceSummaryProps {
  /** Used as params in endpoint call */
  placeType: HeroType;
  /** Used in CardSummary title */
  placeTitle: string;
}

interface PlaceSummaryState {
  /** Error state */
  isError: boolean;
  /** Loading state */
  isLoading: boolean;
  /** Summary data*/
  summary: {
    total_hero: number;
    total_man: number;
    total_woman: number;
    total_place: number;
    response_time: number;
  };
}

interface InjectedProps extends PlaceSummaryProps {
  placeStore: IPlaceStore;
}

@inject("placeStore")
@observer
class PlaceSummary extends React.Component<
  PlaceSummaryProps,
  PlaceSummaryState
> {
  _source = axios.CancelToken.source();
  state = {
    isError: false,
    isLoading: false,
    summary: {
      total_hero: 0,
      total_man: 0,
      total_woman: 0,
      total_place: 0,
      response_time: 0
    }
  };

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    const { placeType } = this.props;
    const { getSummary } = this.injected.placeStore;
    getSummary(this._source.token, placeType);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
  }

  render() {
    const { placeType, placeTitle } = this.props;
    const { isError, isLoading, summary } = this.injected.placeStore[placeType];

    return isLoading ? (
      <LoadingIcon />
    ) : isError ? (
      <div className="alert alert-warning" role="alert">
        <strong>Terjadi kesalahan!</strong> Tidak bisa memperoleh data summary
      </div>
    ) : placeType == "volunteer" ? (
      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-danger"
            iconName="fas fa-chart-bar"
            title="Total Relawan"
            value={summary.total_hero || "-"}
          />
        </div>

        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-warning"
            iconName="fas fa-male"
            title="Total Pria"
            value={summary.total_man || "-"}
          />
        </div>
        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-yellow"
            iconName="fas fa-female"
            title="Total Wanita"
            value={summary.total_woman || "-"}
          />
        </div>
        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-info"
            iconName="fas fa-clock"
            title="Response Time"
            value={`${summary.response_time || "-"} detik`}
          />
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-danger"
            iconName="fas fa-chart-bar"
            title={"Total " + placeTitle}
            value={summary.total.place || "-"}
          />
        </div>

        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-warning"
            iconName="fas fa-user"
            title="Total Hero"
            value={summary.total.hero || "-"}
          />
        </div>
        <div className="col-xl-3 col-lg-6">
          <CardSummary
            iconColor="bg-info"
            iconName="fas fa-clock"
            title="Response Time"
            value={`${summary.response_time || "-"} detik`}
          />
        </div>
        {placeType == "hospital" && (
          <div className="col-xl-3 col-lg-6">
            <CardSummary
              iconColor="bg-info"
              iconName="fas fa-clock"
              title="Jumlah Rujukan"
              value={summary.total.emergency || "-"}
            />
          </div>
        )}
      </div>
    );
  }
}

export { PlaceSummary };
