import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { AuthenticatedUserContext } from "../contexts";

import { auth } from "../api/auth";

interface AuthenticatedRouteState {
  name: string;
  photo: string;
  isError: boolean;
  role: string;
}
class AuthenticatedRoute extends React.Component<any, AuthenticatedRouteState> {
  state = {
    isError: false,
    name: "",
    photo: "",
    role: "",
  };

  componentDidMount() {
    const token = auth.getToken() || "";
    let user: any = null;

    try {
      user = decode(token);

      if (
        user &&
        (user.role_id.group == "superadmin" || user.role_id.group == "admin")
      ) {
        this.setState({
          isError: false,
          name: user.full_name,
          photo: user.photo,
          role: user.role_id.group,
        });
      } else {
        this.setState({ isError: true });
      }
    } catch (err) {
      this.setState({ isError: true });
      console.log("Anda belum login");
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isError, name, photo, role } = this.state;

    return isError ? (
      <Redirect
        to={{
          pathname: "/logout",
        }}
      />
    ) : (
      <AuthenticatedUserContext.Provider value={{ name, photo, role }}>
        <Route
          {...rest}
          render={(props) =>
            auth.isLoggedIn() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </AuthenticatedUserContext.Provider>
    );
  }
}

export { AuthenticatedRoute };
