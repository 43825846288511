import * as React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { DataTable2 } from "./DataTable2";
import { ImageModal } from "./ImageModal";
import { ModalConfirm } from "./ModalConfirm";
import { Countdown } from "./Countdown";

import { axios } from "../api/config";
import { doEmergencyAction } from "../api/emergency";
import { auth } from "../api/auth";
import { addHandler, deleteHandler } from "../api/emergency-ws";
import { observer, inject } from "mobx-react";
import { IEmergencyStore } from "../stores/emergencyStore";
import { c } from "../constant";

interface EmergencyDataTable2Props {
  /** Indicates loading from parent component */
  isLoading: boolean;
  /** Pagination: total rows per page */
  pageSize: number;
  /** Status filter */
  status?: "active" | "all";
}

interface InjectedProps extends EmergencyDataTable2Props {
  emergencyStore: IEmergencyStore;
}

interface EmergencyDataTable2State {
  isImageModal: boolean;
  selectedSrc: string;
}

@inject("emergencyStore")
@observer
class EmergencyDataTable2 extends React.Component<
  EmergencyDataTable2Props,
  EmergencyDataTable2State
> {
  _source = axios.CancelToken.source();
  _socket: any;

  static defaultProps = {
    pageSize: 8,
    status: "all",
  };

  constructor(props: EmergencyDataTable2Props) {
    super(props);

    this._loadData = this._loadData.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
  }

  state = {
    isImageModal: false,
    selectedSrc: "",
  };

  get injected() {
    return this.props as InjectedProps;
  }

  _openImage = (src: string) => {
    this.setState({ isImageModal: true, selectedSrc: src });
  };

  _loadData(page: number) {
    const {
      getActiveEmergencies,
      getEmergencies,
    } = this.injected.emergencyStore;
    if (this.props.status == "active") {
      getActiveEmergencies(this._source.token, page);
    } else {
      getEmergencies(this._source.token, page, c.component.emergencyData);
    }
  }

  async _handleRefresh(e: any) {
    const user = auth.getUserData();
    const data = JSON.parse(e.data);
    if (!data.data) {
      return;
    }
    const emergencyId = data.ide;

    if (emergencyId) {
      if (
        user.role_id.group === "admin" &&
        (user.province !== data.data.sender_data.province ||
          user.city !== data.data.sender_data.city)
      ) {
        return;
      }
      this._loadData(1);
    }
  }

  onClose(type: string) {
    const { updateEmergencyData } = this.injected.emergencyStore;
    updateEmergencyData("selectedId", "");
    updateEmergencyData("selectedName", "");
    if (type === "cancel") {
      updateEmergencyData("isConfirmCancel", false);
      return;
    }
    updateEmergencyData("isConfirmFinish", false);
  }

  onCancel(id, full_name) {
    const { updateEmergencyData } = this.injected.emergencyStore;
    updateEmergencyData("isConfirmCancel", true);
    updateEmergencyData("selectedId", id);
    updateEmergencyData("selectedName", full_name);
  }

  onFinish(id, full_name) {
    const { updateEmergencyData } = this.injected.emergencyStore;
    updateEmergencyData("isConfirmFinish", true);
    updateEmergencyData("selectedId", id);
    updateEmergencyData("selectedName", full_name);
  }

  componentDidMount() {
    this._loadData(1);
    // Register emergency's websocket handler
    addHandler("RELOAD_EMERGENCY_TABLE", this._handleRefresh);
  }

  componentWillUnmount() {
    this._source.cancel("CANCELED");
    // Unregister emergency's websocket handler
    deleteHandler("RELOAD_EMERGENCY_TABLE");
  }

  render() {
    const { isImageModal, selectedSrc } = this.state;
    const {
      isLoading,
      isConfirmCancel,
      isConfirmFinish,
      currentPage,
      totalPages,
      selectedId,
      selectedName,
      rows,
    } = this.injected.emergencyStore.emergencyData;
    return (
      <React.Fragment>
        {isImageModal && (
          <ImageModal
            src={selectedSrc}
            onClosed={() =>
              this.setState({ isImageModal: false, selectedSrc: "" })
            }
          />
        )}
        <ModalConfirm
          isOpen={isConfirmCancel}
          text={`Apakah Anda yakin untuk mengabaikan darurat bantuan dari ${selectedName}?`}
          successMessage={`Darurat bantuan dari ${selectedName} berhasil diabaikan`}
          onConfirmed={() => doEmergencyAction(selectedId, "cancel")}
          onSuccess={() => this._loadData(currentPage)}
          onClosed={() => this.onClose("cancel")}
        />
        <ModalConfirm
          isOpen={isConfirmFinish}
          text={`Apakah Anda yakin untuk menyelesaikan darurat bantuan dari ${selectedName}?`}
          successMessage={`Darurat bantuan dari ${selectedName} berhasil diselesaikan`}
          onConfirmed={() => doEmergencyAction(selectedId, "finish")}
          onSuccess={() => this._loadData(currentPage)}
          onClosed={() => this.onClose("finish")}
        />
        <DataTable2
          headings={["NAMA", "No Telp", "STATUS", "COUNTDOWN", ""]}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          title="List Darurat Bantuan"
          onPaginate={this._loadData}
          renderRows={() =>
            rows.map((emergency: any) => (
              <DataTableRow
                key={emergency._id}
                createdAt={emergency.created_at}
                id={emergency._id}
                photo={null}
                status={emergency.current_status}
                userData={emergency.id_users}
                openImage={this._openImage}
                onCancel={() =>
                  this.onCancel(emergency._id, emergency.id_users.full_name)
                }
                onFinish={() =>
                  this.onFinish(emergency._id, emergency.id_users.full_name)
                }
              />
            ))
          }
        />
      </React.Fragment>
    );
  }
}

function DataTableRow({
  createdAt,
  id,
  status,
  userData,
  onCancel,
  onFinish,
  openImage,
}: any) {
  return (
    <tr>
      <th>
        <div className="media align-items-center">
          <span
            onClick={() => openImage(userData.photo)}
            className="avatar rounded-circle mr-3 cursor-pointer"
          >
            <img
              src={
                userData.photo
                  ? userData.photo
                  : "https://api.virtualpoisoncenter.id/v1/images/icon/user.png"
              }
            />
          </span>
          <div className="media-body">
            <span className="mb-0 text-sm">
              {userData && userData.full_name}
            </span>
          </div>
        </div>
      </th>
      <td>{userData && userData.phone_number ? userData.phone_number : "-"}</td>
      <td>
        <span className="badge badge-dot mr-4">
          <i className="bg-info" />
          {status}
        </span>
      </td>
      <td>
        <span className="mb-0 text-sm">
          <Countdown
            endTime={Date.parse(createdAt) + 5 * 60 * 1000 /* +5min */}
          />
        </span>
      </td>
      <td>
        {(status == "waiting" ||
          status == "accepted" ||
          status == "on_going") && (
          <React.Fragment>
            <Button color="danger" size="sm" onClick={onCancel}>
              Abaikan
            </Button>
            <Button color="success" size="sm" onClick={onFinish}>
              Selesai!
            </Button>
          </React.Fragment>
        )}
        <Link to={"/emergency/" + id} className="btn btn-sm btn-primary">
          Pantau
        </Link>
      </td>
    </tr>
  );
}
export { EmergencyDataTable2 };
