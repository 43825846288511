import { HeroType, axios } from "./config";
import { auth } from "./auth";

function getSummary(cancelToken: any, hero?: HeroType) {
  return axios.get(`/summary/${hero || ""}`, {
    cancelToken: cancelToken,
    headers: {
      "X-Access-Token": auth.getToken()
    }
  });
}

export { getSummary };
