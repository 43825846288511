import { axios } from "./config";
import { auth } from "./auth";

async function getEmergencies(
  cancelToken: any,
  pagination: { size?: number; page?: number }
) {
  try {
    const { data } = await axios.get(
      `/emergency?size=${pagination.size}&page=${pagination.page}`,
      {
        cancelToken: cancelToken,
        headers: {
          "X-Access-Token": auth.getToken()
        }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

async function getActiveEmergencies(
  cancelToken: any,
  pagination: { size?: number; page?: number }
) {
  try {
    const { data } = await axios.get(
      `/emergency/active?size=${pagination.size}&page=${pagination.page}`,
      {
        cancelToken: cancelToken,
        headers: {
          "X-Access-Token": auth.getToken()
        }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

function getEmergency(id: any, cancelToken: any) {
  return axios.get(`/emergency/${id}`, {
    cancelToken: cancelToken,
    headers: {
      "X-Access-Token": auth.getToken()
    }
  });
}

function doEmergencyAction(emergencyId: any, action: "cancel" | "finish") {
  return axios.post(
    "/emergency/status",
    {
      id_emergency: emergencyId,
      action: action
    },
    {
      headers: {
        "X-Access-Token": auth.getToken()
      }
    }
  );
}

function doEmergencyActionAdmin(
  emergencyId: any,
  action: "cancel" | "finish",
  data?: any
) {
  let body: any = {
    id_emergency: emergencyId,
    action: action
  };

  if (action === "finish") {
    body.id_category = data.categoryId;
    body.data = {
      victims_die: data.victimsDied,
      victims_treated: data.victimsTreated
    };
  }

  return axios.post("/emergency/status_admin", body, {
    headers: {
      "X-Access-Token": auth.getToken()
    }
  });
}

function assignHero(emergencyId: any, heroId: any) {
  return axios.post(
    "/emergency/assign_hero",
    {
      id_emergency: emergencyId,
      id_hero: heroId
    },
    {
      headers: {
        "X-Access-Token": auth.getToken()
      }
    }
  );
}

function fetchCategories(cancelToken: any) {
  return axios.get(`/emergency/category`, {
    cancelToken: cancelToken,
    headers: {
      "X-Access-Token": auth.getToken()
    }
  });
}

function storeCategory(name: string) {
  return axios.post(
    `/emergency/category`,
    { name: name },
    {
      headers: {
        "X-Access-Token": auth.getToken()
      }
    }
  );
}

export {
  assignHero,
  doEmergencyAction,
  doEmergencyActionAdmin,
  getEmergency,
  getEmergencies,
  getActiveEmergencies,
  fetchCategories,
  storeCategory
};
