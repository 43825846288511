import * as React from "react";

interface ToastMessageProps {
  /** Fontawesome class icon*/
  icon: string;
  /** Message of the toast */
  text: string;
}

function ToastMessage({ icon, text }: ToastMessageProps) {
  return (
    <React.Fragment>
      <i className={`fa ${icon}`} />
      {" " + text}
    </React.Fragment>
  );
}

export { ToastMessage };
